import { Box, Button, Checkbox, FormControlLabel, Grid, InputBase, Typography } from '@mui/material';
import { IReducer } from 'Reducers/IReducer';
import { IParameters } from 'exactt-types/lib';
import { bindActionCreators } from 'redux';
import AddIcon from '@mui/icons-material/Add';
import { connect } from 'react-redux';
import { ILaboratoryDetails } from 'Reducers/LaboratoryReducer/ILaboratoryReducer';
import { useEffect, useMemo, useState } from 'react';
import { handleChangeWhiteSpace } from 'utils/removeWhiteSpaces';

interface IParameterTableProps {
  value: IParameters[];
  laboratory: null | ILaboratoryDetails;
  setFieldValue: (field: string, value: any) => void;
}

const validationTypeOptions = [
  { label: 'Range', value: 'range' },
  { label: 'Valid', value: 'valid' },
  { label: 'Options', value: 'options' },
  { label: 'Complies', value: 'complies' },
  { label: 'Percentage LC', value: 'percentageLC' },
];

const RenderParameterRowD = ({
  parameter,
  index,
  values,
  laboratory,
  setFieldValue,
  deleteRow,
  addRow,
  disabled,
  editMode,
}: any) => {
  const handleValue = (value: any) => {
    const length = value.length;
    if (value[length - 1] === ' ' && value[length - 2] === ' ') {
      return handleChangeWhiteSpace(value);
    }
    return value;
  };

  return (
    <Box display={'flex'} key={index}>
      <Grid container flex={1} height={45} display={'flex'}>
        {editMode ? (
          <Grid className="table-grid" item xs={0.5}>
            <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <Button
                aria-label={'delete'}
                size={'small'}
                color={'success'}
                onClick={() => {
                  addRow(index);
                }}
              >
                ADD ROW
              </Button>
            </Box>
          </Grid>
        ) : null}

        {editMode ? (
          <Grid className="table-grid" item xs={0.5}>
            <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <Button
                aria-label={'delete'}
                size={'small'}
                color={'error'}
                onClick={() => {
                  deleteRow(index);
                }}
              >
                DELETE
              </Button>
            </Box>
          </Grid>
        ) : null}
        <Grid className="table-grid" item xs={1}>
          <InputBase
            style={{ fontSize: 14, paddingLeft: 10, paddingRight: 10 }}
            // autoFocus
            value={parameter.name}
            disabled={disabled}
            onChange={(e) => {
              const value = e.target.value;
              const key = `parameters.${index}.name`;
              setFieldValue(key, handleValue(value));
            }}
            fullWidth
          />
        </Grid>
        <Grid className="table-grid" item xs={1}>
          <InputBase
            style={{ fontSize: 14, paddingLeft: 10, paddingRight: 10 }}
            value={parameter.resultGroup}
            disabled={disabled}
            onChange={(e) => {
              const value = e.target.value;
              const key = `parameters.${index}.resultGroup`;
              setFieldValue(key, handleValue(value));
            }}
            fullWidth
          />
        </Grid>
        <Grid className="table-grid" item xs={1}>
          <InputBase
            style={{ fontSize: 14, paddingLeft: 10, paddingRight: 10 }}
            disabled={disabled}
            value={parameter.unit}
            onChange={(e) => {
              const value = e.target.value;
              const key = `parameters.${index}.unit`;
              setFieldValue(key, handleValue(value));
            }}
            fullWidth
          />
        </Grid>
        <Grid className="table-grid" item xs={1}>
          {/* <InputLabel id="department-select-label">Department</InputLabel> */}
          <select
            id="demo-simple-select-helper"
            name="department"
            style={{
              width: '100%',
              fontSize: 14,
              border: 'none',
              background: 'none',
              height: '100%',
              textAlign: 'center',
            }}
            disabled={disabled}
            onChange={(e) => {
              const value = e.target.value;
              const key = `parameters.${index}.department`;
              setFieldValue(key, handleValue(value));
            }}
            value={parameter.department}
          >
            <option value={''} disabled></option>
            {laboratory?.departments.map((item: any) => (
              <option value={item._id} key={item._id}>
                {item.name}
              </option>
            ))}
          </select>
        </Grid>
        <Grid className="table-grid" item xs={1.5}>
          <InputBase
            style={{ fontSize: 14, paddingLeft: 10, paddingRight: 10 }}
            value={parameter.method}
            disabled={disabled}
            onChange={(e) => {
              const value = e.target.value;
              const key = `parameters.${index}.method`;
              setFieldValue(key, handleValue(value));
            }}
            fullWidth
          />
        </Grid>
        <Grid className="table-grid" item xs={1}>
          <InputBase
            style={{ fontSize: 14, paddingLeft: 10, paddingRight: 10 }}
            value={parameter.requirements}
            disabled={disabled}
            onChange={(e) => {
              const value = e.target.value;
              const key = `parameters.${index}.requirements`;
              setFieldValue(key, handleValue(value));
            }}
            fullWidth
          />
        </Grid>
        <Grid className="table-grid" item xs={1}>
          <InputBase
            style={{ fontSize: 14, paddingLeft: 10, paddingRight: 10 }}
            value={parameter.permissibleLimit}
            disabled={disabled}
            onChange={(e) => {
              const value = e.target.value;
              const key = `parameters.${index}.permissibleLimit`;
              setFieldValue(key, handleValue(value));
            }}
            fullWidth
          />
        </Grid>
        <Grid className="table-grid" item xs={1}>
          <select
            style={{
              width: '100%',
              fontSize: 14,
              border: 'none',
              background: 'none',
              height: '100%',
              textAlign: 'center',
            }}
            disabled={disabled}
            onChange={(e) => {
              const value = e.target.value as 'range' | 'valid' | 'options' | 'complies';
              const key = `parameters.${index}.validations.type`;
              setFieldValue(key, handleValue(value));
            }}
            value={parameter.validations.type}
          >
            <option value={''} disabled></option>
            {validationTypeOptions.map((item) => (
              <option value={item.value} key={item.value}>
                {item.label}
              </option>
            ))}
          </select>
        </Grid>
        <Grid className="table-grid" item xs={2} height={45}>
          {parameter.validations.type === 'range' && (
            <Grid container height={45}>
              <Grid item height={45} xs={6}>
                <InputBase
                  style={{ fontSize: 14, paddingLeft: 10, paddingRight: 10 }}
                  required
                  placeholder={'Min'}
                  value={parameter.validations.min}
                  type={'number'}
                  disabled={disabled}
                  onChange={(e) => {
                    const value = {
                      ...values[index].validations,
                      min: e.target.value,
                    };
                    const key = `parameters.${index}.validations`;
                    setFieldValue(key, handleValue(value));
                  }}
                  name={'min'}
                  fullWidth
                />
              </Grid>
              <Grid item height={45} xs={6} borderLeft={'2px solid rgba(0,0,0,0.5)'}>
                <InputBase
                  style={{ fontSize: 14, paddingLeft: 10, paddingRight: 10 }}
                  required
                  placeholder={'Max Result'}
                  value={parameter.validations.max}
                  type={'number'}
                  disabled={disabled}
                  onChange={(e) => {
                    const value = {
                      ...values[index].validations,
                      max: e.target.value,
                    };
                    const key = `parameters.${index}.validations`;
                    setFieldValue(key, handleValue(value));
                  }}
                  name={'max'}
                  fullWidth
                />
              </Grid>
            </Grid>
          )}

          {parameter.validations.type === 'options' && (
            <Box>
              <Grid container height={45}>
                <Grid item height={45} xs={4}>
                  <InputBase
                    style={{ fontSize: 14, paddingLeft: 10, paddingRight: 10 }}
                    required
                    placeholder={'Valid Options'}
                    value={parameter.validations.validOptions?.join(',')}
                    disabled={disabled}
                    onChange={(e) => {
                      const value = {
                        ...values[index].validations,
                        validOptions: e.target.value.split(',') as string[],
                      };
                      const key = `parameters.${index}.validations`;
                      setFieldValue(key, handleValue(value));
                    }}
                    name={'validOptions'}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4} height={45} borderLeft={'2px solid rgba(0,0,0,0.5)'}>
                  <InputBase
                    style={{ fontSize: 14, paddingLeft: 10, paddingRight: 10 }}
                    required
                    placeholder={'Invalid Result'}
                    value={parameter.validations.invalidOptions?.join(',')}
                    disabled={disabled}
                    onChange={(e) => {
                      const value = {
                        ...values[index].validations,
                        invalidOptions: e.target.value.split(',') as string[],
                      };
                      const key = `parameters.${index}.validations`;
                      setFieldValue(key, handleValue(value));
                    }}
                    name={'invalidOptions'}
                    fullWidth
                  />
                </Grid>
                <Grid item height={45} xs={4} borderLeft={'2px solid rgba(0,0,0,0.5)'} pl={1}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={parameter.validations.allowMultiple}
                        size="small"
                        onClick={(e) => {
                          const value = {
                            ...values[index].validations,
                            allowMultiple: !(values[index].validations as any).allowMultiple,
                          };
                          const key = `parameters.${index}.validations`;
                          setFieldValue(key, handleValue(value));
                        }}
                      />
                    }
                    label="Multiple"
                    defaultChecked={false}
                    disabled={disabled}
                    onChange={(e) => {}}
                    style={{ fontSize: 10 }}
                  />
                </Grid>
              </Grid>
            </Box>
          )}

          {parameter.validations.type === 'valid' && (
            <Grid container height={45}>
              <Grid item xs={6}>
                <InputBase
                  style={{ fontSize: 14, paddingLeft: 10, paddingRight: 10 }}
                  required
                  placeholder={'Valid Result'}
                  value={parameter.validations.validResult}
                  disabled={disabled}
                  onChange={(e) => {
                    const value = {
                      ...values[index].validations,
                      validResult: e.target.value,
                    };
                    const key = `parameters.${index}.validations`;
                    setFieldValue(key, handleValue(value));
                  }}
                  name={'validResult'}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} borderLeft={'2px solid rgba(0,0,0,0.5)'}>
                <InputBase
                  style={{ fontSize: 14, paddingLeft: 10, paddingRight: 10 }}
                  required
                  placeholder="Invalid Result"
                  value={parameter.validations.invalidResult}
                  disabled={disabled}
                  onChange={(e) => {
                    const value = {
                      ...values[index].validations,
                      invalidResult: e.target.value,
                    };
                    const key = `parameters.${index}.validations`;
                    setFieldValue(key, handleValue(value));
                  }}
                  name={'invalidResult'}
                  fullWidth
                />
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid className="table-grid" item xs={0.5}>
          <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
            <Checkbox
              checked={parameter.isNABL}
              size="small"
              onClick={() => {
                const key = `parameters.${index}.isNABL`;
                setFieldValue(key, !values[index].isNABL);
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

function RenderParameterRow(props: any) {
  return useMemo(
    () => <RenderParameterRowD {...props} deleteRow={props.deleteRow} addRow={props.addRow} />,
    // eslint-disable-next-line
    [props.parameter, props.totalValueLength]
  );
}

function ParameterTable(props: IParameterTableProps) {
  const [editTable, setEditTable] = useState(false);

  const deleteRow = (index: number) => {
    const updatedParameters = [...props.value];
    updatedParameters.splice(index, 1);
    props.setFieldValue('parameters', updatedParameters);
  };

  const addRow = (index: number) => {
    const updatedParameters = [...props.value];
    const newParameter: any = {
      department: '',
      isNABL: false,
      name: '',
      requirements: '',
      resultGroup: '',
      unit: '',
      acceptableLimit: '',
      permissibleLimit: '',
      method: '',
      validations: {
        type: 'complies',
      },
    };
    updatedParameters.splice(index + 1, 0, newParameter);
    props.setFieldValue('parameters', updatedParameters);
  };

  return (
    <Box>
      <Box display={'flex'} justifyContent={'flex-end'} mt={1} gap={2}>
        <Button
          variant="outlined"
          onClick={() => {
            setEditTable(!editTable);
          }}
        >
          {editTable ? 'Done' : 'Edit Existing Rows'}
        </Button>
        {!editTable ? (
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={() => {
              const updatedParameters = props.value.slice();
              updatedParameters.push({
                department: '',
                isNABL: false,
                name: '',
                requirements: '',
                resultGroup: '',
                unit: '',
                acceptableLimit: '',
                permissibleLimit: '',
                method: '',
                validations: {
                  type: 'complies',
                },
              });
              props.setFieldValue('parameters', updatedParameters);
            }}
          >
            Add Parameter
          </Button>
        ) : null}
      </Box>
      <Box
        p={2}
        mt={2}
        bgcolor={editTable ? 'rgba(0,100,100,0.1)' : 'rgba(0,0,0,0.02)'}
        borderRadius={1}
        style={{ overflow: 'scroll' }}
      >
        <Box width={2500}>
          <Box display={'flex'}>
            <Grid container flex={1} height={45} display={'flex'}>
              {editTable ? (
                <Grid className="table-grid center-text" item xs={0.5}>
                  <Typography fontWeight={'bold'} fontSize={12}></Typography>
                </Grid>
              ) : null}
              {editTable ? (
                <Grid className="table-grid center-text" item xs={0.5}>
                  <Typography fontWeight={'bold'} fontSize={12}></Typography>
                </Grid>
              ) : null}

              <Grid className="table-grid center-text" item xs={1}>
                <Typography fontWeight={'bold'} fontSize={12}>
                  NAME
                </Typography>
              </Grid>
              <Grid className="table-grid center-text" item xs={1}>
                <Typography fontWeight={'bold'} fontSize={12}>
                  RESULT GROUP
                </Typography>
              </Grid>
              <Grid className="table-grid center-text" item xs={1}>
                <Typography fontWeight={'bold'} fontSize={12}>
                  UNIT
                </Typography>
              </Grid>
              <Grid className="table-grid center-text" item xs={1}>
                <Typography fontWeight={'bold'} fontSize={12}>
                  DEPARTMENT
                </Typography>
              </Grid>
              <Grid className="table-grid center-text" item xs={1.5}>
                <Typography fontWeight={'bold'} fontSize={12}>
                  METHOD
                </Typography>
              </Grid>
              <Grid className="table-grid center-text" item xs={1}>
                <Typography fontWeight={'bold'} fontSize={12}>
                  REQUIREMENTS
                </Typography>
              </Grid>
              <Grid className="table-grid center-text" item xs={1}>
                <Typography fontWeight={'bold'} fontSize={12}>
                  PERMISSIBLE LIMIT
                </Typography>
              </Grid>
              <Grid className="table-grid center-text" item xs={1}>
                <Typography fontWeight={'bold'} fontSize={12}>
                  RESULT TYPE
                </Typography>
              </Grid>
              <Grid className="table-grid center-text" item xs={2}>
                <Typography fontWeight={'bold'} fontSize={12}>
                  VALIDATIONS
                </Typography>
              </Grid>
              <Grid className="table-grid center-text" item xs={0.5}>
                <Typography fontWeight={'bold'} fontSize={12}>
                  NABL
                </Typography>
              </Grid>
            </Grid>
          </Box>

          {props.value.map((parameter, index) => {
            if (editTable) {
              return (
                <RenderParameterRowD
                  key={index}
                  parameter={parameter}
                  index={index}
                  totalValueLength={props.value.length}
                  values={props.value}
                  laboratory={props.laboratory}
                  setFieldValue={props.setFieldValue}
                  deleteRow={deleteRow}
                  addRow={addRow}
                  disabled={editTable}
                  editMode={editTable}
                />
              );
            }
            return (
              <RenderParameterRow
                key={index}
                parameter={parameter}
                index={index}
                totalValueLength={props.value.length}
                values={props.value}
                laboratory={props.laboratory}
                setFieldValue={props.setFieldValue}
                deleteRow={deleteRow}
                addRow={addRow}
              />
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}

function ParameterTableRenderPrevent(props: IParameterTableProps) {
  return useMemo(
    () => {
      return <ParameterTable {...props} />;
    },
    // eslint-disable-next-line
    [props.value]
  );
}

const mapStateToProps = (state: IReducer) => ({
  user: state.auth.user,
  laboratory: state.laboratory.current,
});

function mapDispatchToProps(dispatch: any) {
  return {
    ...bindActionCreators({}, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ParameterTableRenderPrevent);
