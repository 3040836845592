import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  Grid,
  InputBase,
  ListItemText,
  OutlinedInput,
  TextField,
  Typography,
} from '@mui/material';
import { IHeader } from 'components/Header';
import Header from 'components/Header/Header';
import { useNavigate } from 'react-router-dom';
import { ISampleResultProps } from 'pages/Sample/SampleResult';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IReducer } from 'Reducers/IReducer';
import {
  getSampleById,
  addResult,
  approveResult,
  rejectResult,
  generateURLNumber,
  updateResult,
} from 'actions/LaboratoryActions';
import ScreenLoader from 'components/ScreenLoader/ScreenLoader';
import useQuery from 'utils/getQueryParams';
import { getUserList } from 'actions/UserAction';
import { assignSample } from 'actions/LaboratoryActions';
import moment from 'moment';
import { ISample, ISampleParameter, IUser } from 'exactt-types/lib';
import { DesktopDatePicker, LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterMoment';
import _ from 'lodash';

function _SampleResult(props: ISampleResultProps) {
  const navigate = useNavigate();
  const [analysisDate, setAnalysisDate] = useState(new Date());
  const [completeDate, setCompleteDate] = useState(new Date());
  const [reportDate, setReportDate] = useState(new Date());
  let query = useQuery();
  let sampleId = query.get('id');
  const laboratoryId = props.laboratory?.details?._id || '';

  const [loading, setLoading] = useState<boolean>(true);
  const [parameters, setParameters] = useState<ISampleParameter[] | []>([]);
  const [selectedParameters, setSelectedParameters] = useState<ISampleParameter[] | []>([]);

  const onFocus = async () => {
    if (sampleId) {
      await props.getSampleById(laboratoryId, sampleId);
      setLoading(false);
    }
  };

  const breadcrumbsList: IHeader['breadcrumbsList'] = [
    {
      label: 'Dashboard',
      onClick: () => navigate('/'),
    },
    {
      label: 'Samples',
      onClick: () => navigate('/laboratory/samples'),
    },
    {
      label: props.sampleDetails?.sampleId || '',
      onClick: () => navigate(`/laboratory/samples/sampleDetails?id=${sampleId}`),
    },
  ];

  const sampleDetails = props.sampleDetails;

  const PrintDetails = ({ label, value, width = 230 }: any) => (
    <Box display={'flex'}>
      <Typography lineHeight={1.5} fontSize={15} width={130}>
        {label}
      </Typography>
      <Typography lineHeight={1.5} fontSize={15} color={'ButtonText'} width={width} pr={1}>
        {value}
      </Typography>
    </Box>
  );

  const handleResultUpdate = async (parameter: ISample) => {
    const data = {
      analysisDate: analysisDate || new Date(),
      completeDate: completeDate || new Date(),
      reportDate: reportDate || new Date(),
      ulr: ulr || '',
    };
    await props.updateResult(laboratoryId, data, sampleId || '');
  };

  const findNameFromUserId = (_id: string) => {
    let user = props.userList.find((u) => u.userId === _id);
    return user?.user?.name || '';
  };

  const handleSubmitResult = async (parameter: ISampleParameter) => {
    let data = {
      isNagative: parameter.isNagative || false,
      method: '',
      result: parameter.result,
    };
    await props.addResult(laboratoryId, parameter._id || '', sampleId || '', data);
  };

  const handleApproveResult = async (parameter: ISampleParameter) => {
    await props.approveResult(laboratoryId, parameter._id || '', sampleId || '', props.userId);
  };

  const handleRejectResult = async (parameter: ISampleParameter) => {
    await props.rejectResult(laboratoryId, parameter._id || '', sampleId || '', props.userId);
  };

  useEffect(() => {
    onFocus();
    // eslint-disable-next-line
  }, []);
  const [ulr, setUlr] = useState(sampleDetails?.ulr || '');

  useEffect(() => {
    setParameters(
      props.sampleDetails?.parameters?.map((p, idx) => {
        return {
          ...p,
          result: p.result || parameters[idx]?.result || '',
          isNagative: p.isNagative || parameters[idx]?.isNagative || false,
        };
      }) || []
    );
    setAnalysisDate(props.sampleDetails?.analysisDate || new Date());
    setCompleteDate(props.sampleDetails?.completeDate || new Date());
    setReportDate(props.sampleDetails?.reportDate || new Date());
    // eslint-disable-next-line
  }, [props.sampleDetails]);

  const showAllParameter = props.laboratory?.access?.role === 'ADMIN' || props.laboratory?.access?.role === 'HOD';

  const parameterGroup = _.groupBy(
    parameters.filter((i) => {
      if (showAllParameter) return true;
      return (i.assignTo as any)?._id === props.userId;
    }),
    (p) => (p.assignTo as any)?._id
  );

  return sampleDetails && !loading ? (
    <Box>
      <Header breadcrumbsList={breadcrumbsList} title={sampleDetails.sampleId || ''} />
      <Box p={2} pt={10}>
        <Box display={'flex'} justifyContent={'space-between'} mt={1} mb={1}>
          <Box>
            <Typography variant="h6" fontSize={15} lineHeight={1}>
              {sampleDetails.name}
            </Typography>
            <Typography variant="subtitle1" fontSize={14}>
              {sampleDetails.genericName}
            </Typography>
          </Box>
          <Box>
            {props.sampleDetails?.status === 'PENDING' && (
              <Chip label={'Pending'} variant={'outlined'} color={'error'} />
            )}
            {props.sampleDetails?.status === 'INPROGRESS' && (
              <Chip label={'In process'} variant={'outlined'} color={'info'} />
            )}
            {props.sampleDetails?.status === 'COMPLETED' && (
              <Chip label={'Complete'} variant={'outlined'} color={'success'} />
            )}
          </Box>
        </Box>
        <Divider />

        <Box mt={2}>
          <Typography
            variant="h6"
            fontSize={12}
            lineHeight={1}
            style={{ textTransform: 'uppercase' }}
            color={'ButtonText'}
          >
            Testing Details
          </Typography>
          <Box display={'flex'} mb={2} mt={2}>
            <Grid container>
              <Grid xs={4} item>
                <PrintDetails label={'Sampling Method'} value={sampleDetails.samplingMethod} />
                <PrintDetails label={'Batch No'} value={sampleDetails.batchNo} />
              </Grid>
              <Grid xs={4} item>
                <PrintDetails label={'Sample Date'} value={moment(sampleDetails.collectionDate).format('DD/MM/YYYY')} />
                <PrintDetails label={'Marking'} value={sampleDetails.marking} />
              </Grid>
              <Grid xs={4} item>
                <PrintDetails label={'Due Date'} value={moment(sampleDetails.dueDate).format('DD/MM/YYYY')} />
                <PrintDetails label={'Sample Qty'} value={sampleDetails.sampleQty} />
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <Box mb={2} mt={2}>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <LocalizationProvider dateAdapter={DateAdapter}>
                  <DesktopDatePicker
                    inputFormat={'DD/MM/YYYY'}
                    onChange={(value) => {
                      setAnalysisDate(value as any);
                      console.log('Analysis Date value -> ', value);
                    }}
                    value={analysisDate}
                    disabled={props.sampleDetails?.status === 'COMPLETED' ? true : false}
                    label="Analysis Date"
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select date" fullWidth required margin={'none'} />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={3}>
                <LocalizationProvider dateAdapter={DateAdapter}>
                  <DesktopDatePicker
                    inputFormat={'DD/MM/YYYY'}
                    onChange={(value) => {
                      setCompleteDate(value as any);
                      console.log('Complete Date value -> ', value);
                    }}
                    disabled={props.sampleDetails?.status === 'COMPLETED' ? true : false}
                    value={completeDate}
                    label="Complete Date"
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select date" fullWidth required margin={'none'} />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={3}>
                <LocalizationProvider dateAdapter={DateAdapter}>
                  <DesktopDatePicker
                    inputFormat={'DD/MM/YYYY'}
                    onChange={(value) => {
                      setReportDate(value as any);
                      console.log('Report Date value -> ', value);
                    }}
                    disabled={props.sampleDetails?.status === 'COMPLETED' ? true : false}
                    value={reportDate}
                    label="Report Date"
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select date" fullWidth required margin={'none'} />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              {props.laboratory?.details?.type !== 'PHARMA' ? (
                sampleDetails.ulr ? (
                  <TextField label={'ULR'} disabled margin={'none'} value={sampleDetails.ulr} />
                ) : (
                  <Button
                    onClick={async () => {
                      await props.generateURLNumber(laboratoryId, sampleId || '');
                      await props.getSampleById(laboratoryId, sampleId || '');
                    }}
                  >
                    Generate ULR
                  </Button>
                )
              ) : null}
              <Grid item xs={3} style={{ display: 'flex' }} gap={2}>
                <Button
                  variant={'contained'}
                  disabled={props.sampleDetails?.status === 'COMPLETED' ? true : false}
                  onClick={() => handleResultUpdate(sampleDetails as any)}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Divider />

          <Box
            p={2}
            mt={1}
            minWidth={2000}
            borderRadius={1}
            style={{
              overflowX: 'auto',
            }}
          >
            <Typography
              variant="h6"
              fontSize={12}
              lineHeight={1}
              style={{ textTransform: 'uppercase' }}
              color={'ButtonText'}
            >
              Parameters
            </Typography>
            <Box p={2}>
              <Grid container flex={1} height={45} display={'flex'}>
                <Grid className="table-grid center-text" item xs={2.5}>
                  <Typography
                    variant="h6"
                    fontWeight={'bold'}
                    fontSize={12}
                    lineHeight={1}
                    style={{ textTransform: 'uppercase' }}
                  >
                    Parameter
                  </Typography>
                </Grid>
                <Grid className="table-grid center-text" item xs={1}>
                  <Typography
                    variant="h6"
                    fontWeight={'bold'}
                    fontSize={12}
                    lineHeight={1}
                    style={{ textTransform: 'uppercase' }}
                  >
                    Department
                  </Typography>
                </Grid>
                <Grid className="table-grid center-text" item xs={2}>
                  <Typography
                    variant="h6"
                    fontWeight={'bold'}
                    fontSize={12}
                    lineHeight={1}
                    style={{ textTransform: 'uppercase' }}
                  >
                    Result
                  </Typography>
                </Grid>
                <Grid className="table-grid center-text" item xs={1}>
                  <Typography
                    variant="h6"
                    fontWeight={'bold'}
                    fontSize={12}
                    lineHeight={1}
                    style={{ textTransform: 'uppercase' }}
                  >
                    Unit
                  </Typography>
                </Grid>
                <Grid className="table-grid center-text" item xs={1.5}>
                  <Typography
                    variant="h6"
                    fontWeight={'bold'}
                    fontSize={12}
                    lineHeight={1}
                    style={{ textTransform: 'uppercase' }}
                  >
                    Requirement
                  </Typography>
                </Grid>
                <Grid className="table-grid center-text" item xs={1}>
                  <Typography
                    variant="h6"
                    fontWeight={'bold'}
                    fontSize={12}
                    lineHeight={1}
                    style={{ textTransform: 'uppercase' }}
                  >
                    NABL
                  </Typography>
                </Grid>
                <Grid className="table-grid center-text" item xs={0.75}>
                  <Typography
                    variant="h6"
                    fontWeight={'bold'}
                    fontSize={12}
                    lineHeight={1}
                    style={{ textTransform: 'uppercase' }}
                  >
                    Nagative
                  </Typography>
                </Grid>
                <Grid className="table-grid center-text" item xs={1.75}>
                  <Typography
                    variant="h6"
                    fontWeight={'bold'}
                    fontSize={12}
                    lineHeight={1}
                    style={{ textTransform: 'uppercase' }}
                  >
                    ACTIONS
                  </Typography>
                </Grid>
                <Grid className="table-grid center-text" item xs={0.5}>
                  {showAllParameter ? (
                    <Checkbox
                      checked={
                        selectedParameters.length === 0
                          ? false
                          : selectedParameters.length === parameters.filter((p) => p.status === 'INPROGRESS').length
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedParameters(parameters.filter((p) => p.status === 'INPROGRESS'));
                        } else {
                          setSelectedParameters([]);
                        }
                      }}
                    />
                  ) : null}
                </Grid>
              </Grid>
              {Object.keys(parameterGroup).map((key) => {
                const groupedParameters = parameterGroup[key];
                return (
                  <>
                    <Grid container flex={1} height={45} display={'flex'}>
                      <Grid
                        className="table-grid"
                        item
                        xs={12}
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                      >
                        <Typography fontSize={14} fontWeight={'bold'}>
                          Assiged To: {findNameFromUserId(key)}
                        </Typography>
                        {/* <Box flex={1} pl={2}>
                        <Divider />
                      </Box> */}
                      </Grid>
                    </Grid>

                    {groupedParameters?.map((p) => (
                      <Grid container flex={1} height={45} display={'flex'}>
                        <Grid
                          className="table-grid"
                          item
                          xs={2.5}
                          display={'flex'}
                          pl={1}
                          pr={1}
                          justifyContent={'center'}
                          alignItems={'center'}
                        >
                          <Typography
                            variant="body1"
                            fontSize={13}
                            lineHeight={1}
                            fontWeight={'bold'}
                            textAlign={'center'}
                            color={
                              p.status === 'PENDING'
                                ? 'orange'
                                : p.status === 'INPROGRESS'
                                ? 'blue'
                                : p.status === 'REJECT'
                                ? 'red'
                                : 'green'
                            }
                          >
                            {p.name}
                          </Typography>
                        </Grid>
                        <Grid
                          className="table-grid"
                          item
                          xs={1}
                          display={'flex'}
                          pl={1}
                          pr={1}
                          justifyContent={'center'}
                          alignItems={'center'}
                        >
                          <Typography variant="body1" fontSize={14} lineHeight={1} textAlign={'center'}>
                            {/* @ts-ignore: Unreachable code error */}
                            {p.department?.name}
                          </Typography>
                        </Grid>

                        <Grid
                          item
                          xs={2}
                          display={'flex'}
                          className="table-grid"
                          pl={1}
                          pr={1}
                          justifyContent={'center'}
                          alignItems={'center'}
                        >
                          {(p.status === 'PENDING' || p.status === 'REJECT') && (
                            <>
                              {p?.validations?.type === 'range' && (
                                <InputBase
                                  fullWidth
                                  type={'number'}
                                  margin="none"
                                  style={{ paddingLeft: 5, paddingRight: 5, textAlign: 'center' }}
                                  value={p.result}
                                  onChange={(e) => {
                                    if (p?.validations?.type === 'range') {
                                      let value = parseInt(e.target.value);
                                      let findNagative = value >= p.validations.min && value <= p.validations.max;
                                      let updateItem = parameters.map((i) =>
                                        i._id === p._id
                                          ? { ...i, result: e.target.value, isNagative: !findNagative }
                                          : i
                                      );
                                      setParameters(updateItem);
                                    }
                                  }}
                                />
                              )}
                              {/* @ts-ignore */}
                              {p?.validations?.type === 'percentageLC' && (
                                <InputBase
                                  fullWidth
                                  margin="none"
                                  placeholder="Enter value in %"
                                  style={{ paddingLeft: 5, paddingRight: 5, textAlign: 'center' }}
                                  sx={{ textAlign: 'center' }}
                                  value={p.result}
                                  onChange={(e) => {
                                    let updateItem = parameters.map((i) =>
                                      i._id === p._id ? { ...i, result: e.target.value, isNagative: false } : i
                                    );
                                    setParameters(updateItem);
                                  }}
                                />
                              )}
                              {p?.validations?.type === 'complies' && (
                                <InputBase
                                  fullWidth
                                  margin="none"
                                  style={{ paddingLeft: 5, paddingRight: 5, textAlign: 'center' }}
                                  sx={{ textAlign: 'center' }}
                                  value={p.result}
                                  onChange={(e) => {
                                    let updateItem = parameters.map((i) =>
                                      i._id === p._id ? { ...i, result: e.target.value, isNagative: false } : i
                                    );
                                    setParameters(updateItem);
                                  }}
                                />
                              )}
                              {p.validations?.type === 'valid' && (
                                <FormControl margin="none" fullWidth size="small" required>
                                  <select
                                    id="collectionBy-select-helper"
                                    name="valid"
                                    value={p.result}
                                    onChange={(event) => {
                                      const {
                                        target: { value },
                                      } = event;
                                      if (p?.validations?.type === 'valid') {
                                        let findNagative = value === p.validations.invalidResult;
                                        let updateItem = parameters.map((i) =>
                                          i._id === p._id ? { ...i, result: [value], isNagative: findNagative } : i
                                        );
                                        setParameters(updateItem);
                                      }
                                    }}
                                  >
                                    <option value={p.validations.validResult} key={p.validations.validResult}>
                                      {p.validations.validResult}
                                    </option>
                                    <option value={p.validations.invalidResult} key={p.validations.invalidResult}>
                                      {p.validations.invalidResult}
                                    </option>
                                  </select>
                                </FormControl>
                              )}
                              {p?.validations?.type === 'options' && (
                                <FormControl fullWidth required>
                                  <select
                                    id="demo-multiple-checkbox"
                                    multiple={p?.validations?.allowMultiple || false}
                                    // @ts-ignore: next-line
                                    MenuProps={{
                                      PaperProps: {
                                        style: {
                                          maxHeight: 48 * 4.5 + 8,
                                        },
                                      },
                                    }}
                                    value={p.result || []}
                                    onChange={(event) => {
                                      const {
                                        target: { value },
                                      } = event;

                                      if (p?.validations?.type === 'options') {
                                        let findNagative = false;
                                        if (p?.validations?.allowMultiple) {
                                          // @ts-ignore: Unreachable code error
                                          value.map((i: any) => {
                                            if (p?.validations?.type === 'options') {
                                              let check = p?.validations?.invalidOptions.includes(i);
                                              if (!findNagative) findNagative = check;
                                            }
                                            return i;
                                          });

                                          let updateItem = parameters.map((i) =>
                                            i._id === p._id ? { ...i, result: value, isNagative: findNagative } : i
                                          );
                                          setParameters(updateItem);
                                        } else {
                                          if (p.validations.type === 'options') {
                                            let check = p.validations.invalidOptions.includes(value);
                                            if (!findNagative) findNagative = check;
                                          }

                                          let updateItem = parameters.map((i) =>
                                            i._id === p._id ? { ...i, result: [value], isNagative: findNagative } : i
                                          );
                                          setParameters(updateItem);
                                        }
                                      }
                                    }}
                                    input={<OutlinedInput />}
                                    // @ts-ignore: Unreachable code error
                                    renderValue={(selected) => selected.join(', ')}
                                  >
                                    {[...p.validations.validOptions, ...p.validations.invalidOptions].map((p) => {
                                      return (
                                        // @ts-ignore
                                        <option key={p.name} value={p}>
                                          <ListItemText primary={p} />
                                        </option>
                                      );
                                    })}
                                  </select>
                                </FormControl>
                              )}
                            </>
                          )}
                          {(p.status === 'INPROGRESS' || p.status === 'COMPLETED') && (
                            <Typography
                              variant="body1"
                              fontSize={15}
                              lineHeight={1}
                              textOverflow={'ellipsis'}
                              textAlign={'center'}
                            >
                              {typeof p.result === 'object' ? p.result.join(',') : p.result}
                            </Typography>
                          )}
                        </Grid>
                        <Grid
                          className="table-grid"
                          item
                          xs={1}
                          display={'flex'}
                          pl={1}
                          pr={1}
                          justifyContent={'center'}
                          alignItems={'center'}
                        >
                          <Typography
                            variant="body1"
                            fontSize={15}
                            lineHeight={1}
                            textAlign={'center'}
                            textOverflow={'ellipsis'}
                            // overflow={'auto'}
                          >
                            {p.unit}
                          </Typography>
                        </Grid>
                        <Grid
                          className="table-grid"
                          item
                          xs={1.5}
                          display={'flex'}
                          pl={1}
                          pr={1}
                          justifyContent={'center'}
                          alignItems={'center'}
                        >
                          <Typography
                            variant="body1"
                            fontSize={15}
                            lineHeight={1}
                            textOverflow={'ellipsis'}
                            textAlign={'center'}
                            // overflow={'auto'}
                          >
                            {p.requirements}
                          </Typography>
                        </Grid>
                        <Grid
                          className="table-grid"
                          item
                          xs={1}
                          display={'flex'}
                          pl={1}
                          pr={1}
                          justifyContent={'center'}
                          alignItems={'center'}
                        >
                          <Typography
                            variant="body1"
                            fontSize={13}
                            lineHeight={1}
                            textOverflow={'ellipsis'}
                            // overflow={'auto'}
                            textAlign={'center'}
                            fontWeight={'bold'}
                          >
                            {p.isNABL ? 'NABL' : 'NON-NABL'}
                          </Typography>
                        </Grid>
                        <Grid
                          className="table-grid"
                          item
                          xs={0.75}
                          display={'flex'}
                          pl={1}
                          pr={1}
                          justifyContent={'center'}
                          alignItems={'center'}
                        >
                          {p?.validations?.type === 'complies' ? (
                            '-'
                          ) : (
                            <Typography
                              variant="body1"
                              fontSize={15}
                              lineHeight={1}
                              textOverflow={'ellipsis'}
                              textAlign={'center'}
                              // overflow={'auto'}
                            >
                              {p.isNagative ? 'Fail' : 'Pass'}
                            </Typography>
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={1.75}
                          minHeight={30}
                          display={'flex'}
                          pl={1}
                          pr={1}
                          justifyContent={'center'}
                          alignItems={'center'}
                          className="table-grid"
                        >
                          {(p.status === 'PENDING' || p.status === 'REJECT') && (
                            <Box display={'flex'}>
                              <Button color="success" onClick={() => handleSubmitResult(p)} disabled={!p.result}>
                                Submit
                              </Button>
                            </Box>
                          )}
                          {p.status === 'INPROGRESS' && props.hasMasterAccess && (
                            <Box display={'flex'}>
                              <Button color="success" style={{ padding: 0 }} onClick={() => handleApproveResult(p)}>
                                Approve
                              </Button>
                              <Button color="error" style={{ padding: 0 }} onClick={() => handleRejectResult(p)}>
                                Reject
                              </Button>
                            </Box>
                          )}
                        </Grid>
                        <Grid className="table-grid center-text" item xs={0.5}>
                          {/* Checkbox if parameter is selected then add into selectedParameters */}
                          {showAllParameter ? (
                            <Checkbox
                              checked={selectedParameters.map((o) => o._id).includes(p._id)}
                              disabled={p.status !== 'INPROGRESS'}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setSelectedParameters([...selectedParameters, p]);
                                } else {
                                  setSelectedParameters(selectedParameters.filter((sp) => sp._id !== p._id));
                                }
                              }}
                            />
                          ) : null}
                        </Grid>
                      </Grid>
                    ))}
                  </>
                );
              })}
            </Box>
            {showAllParameter ? (
              <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'} gap={2}>
                <Box fontWeight={'bold'}>Selected Parameters : {selectedParameters.length}</Box>
                <Button
                  disabled={!selectedParameters.length}
                  onClick={async () => {
                    await Promise.all(
                      selectedParameters.map((p) => {
                        return handleApproveResult(p);
                      })
                    );
                  }}
                >
                  Approve Selected
                </Button>
                <Button
                  disabled={!selectedParameters.length}
                  color="error"
                  onClick={async () => {
                    await Promise.all(
                      selectedParameters.map((p) => {
                        return handleRejectResult(p);
                      })
                    );
                  }}
                >
                  Reject Selected
                </Button>
              </Box>
            ) : (
              <Divider />
            )}
          </Box>
          <Divider sx={{ marginTop: 1 }} />
        </Box>
      </Box>
    </Box>
  ) : (
    <ScreenLoader />
  );
}

const mapStateToProps = (state: IReducer) => ({
  userId: state.auth.user?._id || '',
  hasMasterAccess: state.laboratory.current?.access?.accessList?.master || false,
  laboratory: state.laboratory.current,
  userList: state.laboratory.users,
  user: state.auth.user as IUser,
  sampleDetails: state.laboratory.sampleDetails,
  updateResult: state.laboratory.sampleResult,
});

function mapDispatchToProps(dispatch: any) {
  return {
    ...bindActionCreators(
      {
        getSampleById,
        getUserList,
        assignSample,
        addResult,
        approveResult,
        rejectResult,
        generateURLNumber,
        updateResult,
      },
      dispatch
    ),
  };
}

export const SampleResult = connect(mapStateToProps, mapDispatchToProps)(_SampleResult);
