import { connect } from 'react-redux';
import { Box, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { IReducer } from 'Reducers/IReducer';
import { ISideDrawer } from 'components/SideDrawer';
import { logout } from 'actions/AuthActions';
import { bindActionCreators } from 'redux';
import { useNavigate, useLocation } from 'react-router-dom';
import Hotkeys from 'react-hot-keys';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import BusinessIcon from '@mui/icons-material/Business';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import InsertPageBreakIcon from '@mui/icons-material/InsertPageBreak';
import LocalPrintshopTwoToneIcon from '@mui/icons-material/LocalPrintshopTwoTone';
import PaymentsIcon from '@mui/icons-material/Payments';
import useStyles from './styles';
import translations from 'utils/translations';

function _SideDrawer(props: ISideDrawer) {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const isPrintPage = location.pathname.includes('print');

  const keyboardHandler = (keyName: string): void => {
    switch (keyName) {
      case 'shift+h':
        navigate('/');
        return;
      case 'shift+l':
        navigate('/laboratory/create');
        return;
    }
  };

  if (isPrintPage) {
    return null;
  }

  return (
    <Hotkeys
      keyName="shift+h,shift+l"
      onKeyDown={(keyName, e, handle) => {
        keyboardHandler(keyName);
      }}
    >
      <Box display={'flex'}>
        <Box className={classes.drawer} style={{ display: props.laboratory ? 'block' : 'none' }} pt={4}>
          <Box p={2} pb={1} display={'flex'} flexDirection={'column'} alignItems={'center'}>
            {/* <img src={props.laboratory?.details.logo} alt="laboratoy_image" style={{ height: 70, marginBottom: 10 }} />
            <Typography textAlign={'center'} fontSize={12} fontWeight={'bold'}>
              {props.laboratory?.details.name}
            </Typography> */}
          </Box>

          <List>
            <ListItem button key={'dashdoard'} onClick={() => navigate('/')}>
              <ListItemIcon>
                <DashboardOutlinedIcon color="secondary" style={{ fontSize: '20px' }} />
              </ListItemIcon>
              <ListItemText primary={translations.components.sideDrawer.dashboard} />
            </ListItem>
            {props.laboratory?.access.accessList.samples && (
              <ListItem
                button
                key={'sample'}
                onClick={() => {
                  navigate('/laboratory/samples');
                }}
              >
                <ListItemIcon>
                  <ReceiptLongIcon color="secondary" style={{ fontSize: '20px' }} />
                </ListItemIcon>
                <ListItemText primary={'Sample'} />
              </ListItem>
            )}
            {props.laboratory?.access.accessList.laboratorySetting && (
              <ListItem button key={'users'} onClick={() => navigate('/laboratory/users')}>
                <ListItemIcon>
                  <PeopleAltOutlinedIcon color="secondary" style={{ fontSize: '20px' }} />
                </ListItemIcon>
                <ListItemText primary={translations.components.sideDrawer.users} />
              </ListItem>
            )}
            {props.laboratory?.access.accessList.customer && (
              <ListItem button key={'customers'} onClick={() => navigate('/laboratory/customers')}>
                <ListItemIcon>
                  <SupervisedUserCircleIcon color="secondary" style={{ fontSize: '20px' }} />
                </ListItemIcon>
                <ListItemText primary={translations.components.sideDrawer.customers} />
              </ListItem>
            )}
            {props.laboratory?.access.accessList.testGroup && (
              <ListItem button key={'testgroups'} onClick={() => navigate('/laboratory/testgroups')}>
                <ListItemIcon>
                  <WorkspacesIcon color="secondary" style={{ fontSize: '20px' }} />
                </ListItemIcon>
                <ListItemText primary={'Test Group'} />
              </ListItem>
            )}
            {props.laboratory?.access.accessList.master && (
              <ListItem button key={'master'} onClick={() => navigate('/laboratory/masters')}>
                <ListItemIcon>
                  <LibraryBooksOutlinedIcon color="secondary" style={{ fontSize: '20px' }} />
                </ListItemIcon>
                <ListItemText primary={translations.components.sideDrawer.master} />
              </ListItem>
            )}
            {props.laboratory?.access.accessList.accounting && (
              <ListItem button key={'priceList'} onClick={() => navigate('/laboratory/price-list')}>
                <ListItemIcon>
                  <PaymentsIcon color="secondary" style={{ fontSize: '20px' }} />
                </ListItemIcon>
                <ListItemText primary={'Price List'} />
              </ListItem>
            )}

            {props.laboratory?.access.accessList.reference && (
              <ListItem button key={'Reference'} onClick={() => navigate('/laboratory/references')}>
                <ListItemIcon>
                  <RoomPreferencesIcon color="secondary" style={{ fontSize: '20px' }} />
                </ListItemIcon>
                <ListItemText primary={'Reference'} />
              </ListItem>
            )}
            {props.laboratory?.access.accessList.department && (
              <ListItem button key={'Department'} onClick={() => navigate('/laboratory/departments')}>
                <ListItemIcon>
                  <BusinessIcon color="secondary" style={{ fontSize: '20px' }} />
                </ListItemIcon>
                <ListItemText primary={'Department'} />
              </ListItem>
            )}
            {/* {props.laboratory?.access.accessList.accounting && (
              <ListItem button key={'Audit'} onClick={() => navigate('/laboratory/audits')}>
                <ListItemIcon>
                  <VerifiedUserIcon color="secondary" style={{ fontSize: '20px' }} />
                </ListItemIcon>
                <ListItemText primary={'Audit'} />
              </ListItem>
            )} */}
            {props.laboratory?.access.accessList.resultTemplate && (
              <ListItem button key={'resultTemplate'} onClick={() => navigate('/laboratory/resultTemplate')}>
                <ListItemIcon>
                  <InsertPageBreakIcon color="secondary" style={{ fontSize: '20px' }} />
                </ListItemIcon>
                <ListItemText primary={'Result Template'} />
              </ListItem>
            )}
            {props.laboratory?.access.accessList.accounting && (
              <ListItem button key={'registerModule'} onClick={() => navigate('/laboratory/register')}>
                <ListItemIcon>
                  <LocalPrintshopTwoToneIcon color="secondary" style={{ fontSize: '20px' }} />
                </ListItemIcon>
                <ListItemText primary={'Register'} />
              </ListItem>
            )}
          </List>
        </Box>
      </Box>
    </Hotkeys>
  );
}

const mapStateToProps = (state: IReducer) => ({
  user: state.auth.user,
  laboratory: state.laboratory.current,
});

function mapDispatchToProps(dispatch: any) {
  return {
    ...bindActionCreators({ logout }, dispatch),
  };
}

export const SideDrawer = connect(mapStateToProps, mapDispatchToProps)(_SideDrawer);
