import { useEffect, useState } from 'react';
import { Box, Button, Divider, TextField, Typography } from '@mui/material';
import { IHeader } from 'components/Header';
import Header from 'components/Header/Header';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { ISampleProps } from 'pages/Sample';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IReducer } from 'Reducers/IReducer';
import { searchSample, searchTestGroupList } from 'actions/LaboratoryActions';
import { SampleTable } from './SampleTable/SampleTable';
import moment from 'moment';
import { DesktopDatePicker, LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterMoment';
import { ITestGroup, ISample } from 'exactt-types/lib';
import { SearchDialog } from 'components/SearchDialog';

function _Sample(props: ISampleProps) {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [startDate, setStartDate] = useState<null | string>(null);
  const [endDate, setEndDate] = useState<null | string>(null);
  const [selectedTestGroup, setSelectedTestGroup] = useState<ITestGroup | null>(null);
  const [testGroupSearchModal, setTestGroupSearchModal] = useState(false);
  const [testGroupSearchQuery, setTestGroupSearchQuery] = useState('');

  // Pagination
  const [page, setPage] = useState(1);
  const size = 10;
  const [loading, setLoading] = useState(false);

  // Load samples function
  const loadSamples = (reset = false) => {
    setLoading(true);
    props
      .searchSample(props.laboratory?.details?._id || '', {
        page: page,
        size: size,
        query: searchQuery,
        startDate: startDate || '',
        endDate: endDate || '',
        testGroup: selectedTestGroup?._id,
      })
      .then(() => {
        setLoading(false);
      });
  };

  const loadTestGroup = (reset = false) => {
    setLoading(true);
    props
      .searchTestGroupList(props.laboratory?.details?._id || '', {
        page: 1,
        size: 10,
        query: testGroupSearchQuery,
      })
      .then(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setPage(1);
    loadSamples(true);
    loadTestGroup(true);

    // eslint-disable-next-line
  }, [searchQuery, startDate, endDate, selectedTestGroup, testGroupSearchQuery]);

  // Load more samples when page changes
  useEffect(() => {
    if (page > 1) {
      loadSamples();
    }
    // eslint-disable-next-line
  }, [page]);

  // Handle load more button click
  const handleLoadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  // Breadcrumbs
  const breadcrumbsList: IHeader['breadcrumbsList'] = [
    {
      label: 'Dashboard',
      onClick: () => navigate('/'),
    },
    {
      label: 'Samples',
      onClick: () => navigate('/laboratory/samples'),
    },
  ];

  return (
    <Box>
      <Header
        breadcrumbsList={breadcrumbsList}
        title="Samples"
        right={
          <Box display={'flex'} alignItems={'center'}>
            <TextField
              label={'Search'}
              size={'small'}
              value={searchQuery}
              sx={{ width: 250, p: 0 }}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            {props.laboratory?.access.role === 'ADMIN' || props.laboratory?.access.role === 'HOD' ? (
              props.laboratory?.details?.labType === 'PHARMA' ? (
                <>
                  <Button
                    style={{ marginLeft: 5 }}
                    variant={'contained'}
                    size="medium"
                    startIcon={<AddIcon />}
                    onClick={() => navigate('/laboratory/samples/persist-pharma-sample')}
                  >
                    Add New Sample
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    style={{ marginLeft: 5 }}
                    variant={'contained'}
                    size="medium"
                    startIcon={<AddIcon />}
                    onClick={() => navigate('/laboratory/samples/persistSampleFood-Water')}
                  >
                    Food / Water Sample
                  </Button>
                  <Button
                    size="medium"
                    style={{ marginLeft: 5 }}
                    variant={'contained'}
                    startIcon={<AddIcon />}
                    onClick={() => navigate('/laboratory/samples/KSZ')}
                  >
                    KSZ
                  </Button>
                </>
              )
            ) : null}
          </Box>
        }
      />

      <Box p={2} pt={12}>
        <Box display={'flex'} alignContent={'center'} gap={1}>
          <LocalizationProvider dateAdapter={DateAdapter}>
            <DesktopDatePicker
              label="Start Date"
              inputFormat="DD-MM-YYYY"
              value={startDate ? moment(startDate, 'YYYY-MM-DD').toDate() : null}
              onChange={(data) => {
                setStartDate(moment(data).format('YYYY-MM-DD'));
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={DateAdapter}>
            <DesktopDatePicker
              label="End Date"
              inputFormat="DD-MM-YYYY"
              value={endDate ? moment(endDate, 'YYYY-MM-DD').toDate() : null}
              onChange={(data) => {
                setEndDate(moment(data).format('YYYY-MM-DD'));
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>

          <TextField
            label={'Test Group'}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setTestGroupSearchModal(true);
              }
            }}
            value={selectedTestGroup?.name || ''}
          />
        </Box>
        <SampleTable data={props.sampleList || []} role={props.laboratory?.access.role || ''} />
      </Box>

      <Box display={'flex'} justifyContent={'center'} alignContent={'center'} gap={1} mt={2}>
        <Button onClick={handleLoadMore} disabled={loading}>
          {loading ? 'Loading...' : 'Load More'}
        </Button>
      </Box>

      <SearchDialog
        isVisible={testGroupSearchModal}
        title={'Search Test Group'}
        body={
          <Box>
            <TextField
              fullWidth
              label={'Search'}
              value={testGroupSearchQuery}
              autoFocus
              onChange={(e) => {
                setTestGroupSearchQuery(e.target.value);
              }}
            />
            {props.testGroupsList.map((m) => (
              <Box
                key={m._id}
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  setSelectedTestGroup(m);
                  setTestGroupSearchModal(false);
                  setTestGroupSearchQuery('');
                }}
              >
                <Box display={'flex'} pt={1} pb={1} alignItems={'center'} justifyContent={'space-between'}>
                  <Typography variant="h6" color={'#000'} fontSize={14}>
                    {m.name}
                  </Typography>
                  <Typography variant="subtitle1" color={'#000'} fontSize={14} pl={1}>
                    {m.prefix}
                  </Typography>
                </Box>
                <Divider />
              </Box>
            ))}
          </Box>
        }
        onAction={() => {}}
        onClose={() => {
          setTestGroupSearchModal(false);
          setTestGroupSearchQuery('');
        }}
        hideButtons={true}
      />
    </Box>
  );
}

const mapStateToProps = (state: IReducer) => ({
  user: state.auth.user,
  laboratory: state.laboratory.current,
  userList: state.laboratory.users,
  department: state.department.list,
  sampleList: state.laboratory.samples,
  count: state.laboratory.count.sample,
  testGroupsList: state.laboratory.testGroups,
});

function mapDispatchToProps(dispatch: any) {
  return {
    ...bindActionCreators({ searchSample, searchTestGroupList }, dispatch),
  };
}

export const Sample = connect(mapStateToProps, mapDispatchToProps)(_Sample);
