import { Box, MenuItem, Select } from '@mui/material';
import { getSampleById } from 'actions/LaboratoryActions';
// import GeneralPwdHeader from 'PdfPages/components/PwdHeader';

// import GeneralPwdFooter from 'PdfPages/components/PwdFooter';

import A4 from 'PdfPages/pages/A4';
import { useEffect, useState } from 'react';
import useQuery from 'utils/getQueryParams';
import { connect } from 'react-redux';
import { IReducer } from 'Reducers/IReducer';
import { bindActionCreators } from 'redux';
import { ILaboratoryDetails } from 'Reducers/LaboratoryReducer/ILaboratoryReducer';
import { IMaster, ISample, IUser } from 'exactt-types/lib';
import GeneralFields, { GeneralFieldsValue } from 'PdfPages/components/GeneralFields';
import DocumentLatterHead from 'PdfPages/components/DocumentLatterHead';
import moment from 'moment';
import QRCode from 'react-qr-code';
import _ from 'lodash';
import GeneralPharmaTable2 from 'PdfPages/components/GeneralPharmaTable2';

interface SampleResultPrintProps {
  setDisableScroll: (value: boolean) => void;
  getSampleById: (laboratoryId: string, _id: string) => Promise<any>;
  laboratory: ILaboratoryDetails | null;
  sampleDetails: ISample | null;
}

export function _SampleJobSheetPrint(props: SampleResultPrintProps) {
  let query = useQuery();
  let sampleId = query.get('id');

  const [selectedUser, setSelectedUser] = useState('all');

  const onFocus = async () => {
    if (sampleId) {
      await props.getSampleById(props.laboratory?.details._id || '', sampleId);
      setTimeout(() => {
        // window.print();
      }, 1000 * 5);
      // setLoading(false);
    }
  };

  useEffect(() => {
    props.setDisableScroll(true);
    onFocus();

    // eslint-disable-next-line
  }, []);

  const values: GeneralFieldsValue[] = [];

  if (props.sampleDetails) {
    values.push({ label: 'Commodity', value: props.sampleDetails.name });
    values.push({ label: 'Lab Code', value: props.sampleDetails.sampleId || '' });
    values.push({ label: 'Batch No', value: props.sampleDetails.batchNo || '' });
    values.push({ label: 'Mfg Date', value: moment(props.sampleDetails.mfgDate).format('MM/YY') || '' });
    values.push({
      label: 'Assigned To',
      value:
        props.sampleDetails?.parameters
          ?.filter((f) => {
            // @ts-ignore
            return (f.assignTo as IUser)?._id === selectedUser;
          })
          // @ts-ignore
          ?.map((i) => (i.assignTo as IUser)?.name)[0] || 'All Users',
    });
    values.push({ label: 'Exp Date', value: moment(props.sampleDetails.expDate).format('MM/YY') || '' });
    values.push({
      label: 'Date of receipt',
      value: moment(props.sampleDetails.collectionDate).format('DD-MM-YYYY') || '',
    });
    values.push({ label: 'Date of Completion', value: '' });
    if (props.sampleDetails.description)
      values.push({ label: 'Assay Descriptions', value: props.sampleDetails.description || '', fullWidth: true });
  }

  const parameters: any[] = [];

  if (props.sampleDetails?.parameters) {
    (props.sampleDetails?.master as IMaster)?.parameters?.forEach((parameter) => {
      // find parameter from master list (props.sampleDetails?.master?.parameters)

      const sampleParameter = props.sampleDetails?.parameters?.find((p) => p.name === parameter.name);

      console.log('sampleParameter -> ', sampleParameter);

      if (sampleParameter) {
        parameters.push({
          characteristic: parameter.name,
          results: sampleParameter?.result || '',
          unit: parameter.unit,
          requirements: parameter.requirements,
          permissibleLimit: parameter?.permissibleLimit || '-',
          method: parameter?.method,
          group: parameter?.resultGroup,
          type: parameter?.validations?.type,
        });
      }
    });
  }

  console.log('sampleParameter -> ', parameters);

  let users = {};

  props.sampleDetails?.parameters?.map((i, index) => {
    // @ts-ignore
    if ((i.assignTo as IUser)?._id) {
      // @ts-ignore
      users[(i.assignTo as IUser)?._id] = i.assignTo as IUser;
    }
  });

  const hasAdditionalFields = (props.sampleDetails?.additionalFields
    ? Object.keys(props.sampleDetails?.additionalFields)
    : []
  ).length
    ? true
    : false;

  return (
    <Box>
      {/* Select for users */}
      <Select
        style={{ width: 500 }}
        size="small"
        className="no-print"
        value={selectedUser}
        onChange={(e) => setSelectedUser(e.target.value as string)}
      >
        <MenuItem value={'all'}>All</MenuItem>
        <MenuItem value={'not_assigned'}>Not Assigned</MenuItem>
        {Object.keys(users).map((i) => (
          <MenuItem value={i}>{users[i].name}</MenuItem>
        ))}
      </Select>
      <A4
        header={<DocumentLatterHead laboratory={props.laboratory} />}
        body={
          <>
            <Box mt={-0.5} border={'1px solid #000'} p={2}>
              <Box textAlign={'center'} mb={1} fontWeight={'bold'}>
                JOB SHEET
              </Box>
              <GeneralFields values={values} labelWidth={134} />
              <Box display={'flex'} justifyContent={'flex-end'}>
                <div>
                  <div style={{ height: 'auto', margin: '0 auto', maxWidth: 32, width: '100%' }}>
                    <QRCode
                      size={256}
                      style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                      value={props.sampleDetails?.sampleId as string}
                      viewBox={`0 0 256 256`}
                    />
                  </div>
                </div>
              </Box>
            </Box>
            <Box className="table-with-border" style={{ marginTop: -21 }}>
              <GeneralPharmaTable2 sample={props.sampleDetails} fields={parameters} hideResult showFooter />
            </Box>
          </>
        }
        footer={<></>}
        // finalFooter={<GeneralFinalFooter />}
      />
    </Box>
  );
}

const mapStateToProps = (state: IReducer) => ({
  laboratory: state.laboratory.current,
  sampleDetails: state.laboratory.sampleDetails,
});

function mapDispatchToProps(dispatch: any) {
  return {
    ...bindActionCreators(
      {
        getSampleById,
      },
      dispatch
    ),
  };
}

export const SampleJobSheetPrintPharma = connect(mapStateToProps, mapDispatchToProps)(_SampleJobSheetPrint);
