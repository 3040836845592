import { Box, Typography } from '@mui/material';
import { getSampleById, getResultTemplateById } from 'actions/LaboratoryActions';
// @ts-ignore
import HTMLRenderer from 'react-html-renderer';
import A4 from 'PdfPages/pages/A4';
import { useEffect, useState } from 'react';
import useQuery from 'utils/getQueryParams';
import { connect } from 'react-redux';
import { IReducer } from 'Reducers/IReducer';
import { bindActionCreators } from 'redux';
import { ILaboratoryDetails } from 'Reducers/LaboratoryReducer/ILaboratoryReducer';
import { ICustomer, IMaster, IResultTemplate, ISample } from 'exactt-types/lib';
import { editorTokens } from 'pages/ResultTemplate/PersistResultTemplate/editorTokens';
import './print.css';
import QRCode from 'react-qr-code';
import moment from 'moment';
import GeneralTable from 'PdfPages/components/GeneralTable';
import { common } from 'constants/ImagesFiles';
import GeneralTable2 from 'PdfPages/components/GeneralTable2';
import GeneralPharmaTable2 from 'PdfPages/components/GeneralPharmaTable2';

const tokensById: any = {};
editorTokens.forEach((token) => {
  tokensById[token.id] = token;
});

const TOKEN_MATCHER = /\{\{(\w*)\|(\w*)\}\}/gi;

interface SampleResultPrintProps {
  setDisableScroll: (value: boolean) => void;
  getSampleById: (laboratoryId: string, _id: string) => Promise<any>;
  getResultTemplateById: (laboratoryId: string, _id: string) => Promise<any>;
  laboratory: ILaboratoryDetails | null;
  sampleDetails: ISample | null;
}

export function _SampleResultPrintV2(props: SampleResultPrintProps) {
  const [resultTemplate, setResultTemplate] = useState<IResultTemplate | null>(null);
  const customerAddress = (props.sampleDetails?.customer as ICustomer)?.address?.[0];

  let query = useQuery();
  let sampleId = query.get('id');
  let resultFormat = query.get('resultFormat');
  let printWithNABL = query.get('printWithNABL');

  const onFocus = async () => {
    if (sampleId) {
      await props.getSampleById(props.laboratory?.details._id || '', sampleId);
      const res = await props.getResultTemplateById(props.laboratory?.details._id || '', resultFormat as string);
      setResultTemplate(res);
      setTimeout(() => {
        // window.print();
      }, 1000 * 5);
      // setLoading(false);
    }
  };

  const tokensToHtml = (tokenizedString: any, tokensById: any) => {
    return tokenizedString.replace(TOKEN_MATCHER, (match: any, slug: any, id: any) => {
      const token = tokensById[id];
      // Ignore matches that aren't for a real token.
      if (!token) {
        return match;
      }

      if (token.id === 'logo') {
        return `<logo />`;
      }
      if (token.id === 'customerName') {
        return `<customername />`;
      }

      if (token.id === 'sampleIdQRCode') {
        return `<qr />`;
      }

      if (token.id === 'resultParameterTable') {
        return `<resultparametertable />`;
      }

      if (token.id === 'resultParameterTableTwo') {
        return `<resultparametertabletwo />`;
      }

      if (token.id === 'resultparameterpharmatabletwo') {
        return `<resultparameterpharmatabletwo />`;
      }

      if (token.id === 'customerAddress') {
        return `<customeraddress />`;
      }

      if (token.id === 'collectionBy') {
        return `<collectionby />`;
      }

      if (token.id === 'hod') {
        return `<hod />`;
      }

      if (token.id === 'expDateMMYY') {
        return `<expdatemmyy />`;
      }

      if (token.id === 'mfgDateMMYY') {
        return `<mfgdatemmyy />`;
      }

      if (token.id === 'reviewedBy') {
        return `<reviewedby />`;
      }

      if (token.id === 'stamp') {
        return `<stamp />`;
      }

      if (token.id === 'authorizedSignature1') {
        return `<authorizedsignature1 />`;
      }

      if (token.id === 'authorizedSignature2') {
        return `<authorizedsignature2 />`;
      }
      if (token.id === 'refNo') {
        return `<refno />`;
      }

      if (
        token.id === 'labDueDate' ||
        token.id === 'dueDate' ||
        token.id === 'collectionDate' ||
        token.id === 'mfgDate' ||
        token.id === 'expDate' ||
        token.id === 'mfgDate' ||
        token.id === 'dateAndPlaceOfCollectionOfSample' ||
        token.id === 'completeDate' ||
        token.id === 'analysisDate' ||
        token.id === 'reportDate' ||
        token.id === 'expDateForFoodSample' ||
        token.id === 'dateOfDispatch'
      ) {
        return moment(props.sampleDetails?.[`${token.id}` as keyof ISample]).format('DD/MM/YYYY');
      }

      return props.sampleDetails?.[`${token.id}` as keyof ISample] || 'N/A';
    });
  };

  const headerText = tokensToHtml(resultTemplate?.header || '', tokensById);
  const bodyText = tokensToHtml(resultTemplate?.body || '' || '', tokensById);
  const footerText = tokensToHtml(resultTemplate?.footer || '' || '', tokensById);

  useEffect(() => {
    props.setDisableScroll(true);
    onFocus();
    // eslint-disable-next-line
  }, []);

  const parameters: any[] = [];

  if (props.sampleDetails?.parameters) {
    (props.sampleDetails?.master as IMaster)?.parameters?.forEach((parameter) => {
      // find parameter from master list (props.sampleDetails?.master?.parameters)

      const sampleParameter = props.sampleDetails?.parameters?.find((p) => p.name === parameter.name);

      let isValidParameterForPrint;

      if (printWithNABL === 'YES' && parameter.isNABL === true) {
        isValidParameterForPrint = true;
      } else if (printWithNABL === 'NO' && parameter.isNABL === false) {
        isValidParameterForPrint = true;
      } else if (printWithNABL === 'ALL') {
        isValidParameterForPrint = true;
      } else {
        isValidParameterForPrint = false;
      }

      console.log('isValidParameterForPrint -> ', isValidParameterForPrint);

      if (!isValidParameterForPrint) {
        return;
      }

      if (sampleParameter) {
        parameters.push({
          characteristic: parameter.name,
          results: sampleParameter?.result || '',
          unit: parameter.unit,
          requirements: parameter.requirements,
          permissibleLimit: parameter?.permissibleLimit || '-',
          method: parameter?.method,
          group: parameter?.resultGroup,
          type: parameter?.validations?.type,
        });
      }
    });
  }

  const components = {
    logo: (_props: any) => (
      <img src={props.laboratory?.details?.logo} alt="logo" style={{ width: '20mm', objectFit: 'contain' }} />
    ),
    qr: (_props: any) => (
      <div style={{ height: '30px', width: '30px', overflow: 'hidden' }}>
        <QRCode
          size={10}
          style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
          value={props.sampleDetails?.sampleId as string}
          viewBox={`0 0 256 256`}
        />
      </div>
    ),

    customername: (_props: any) => {
      const customer = props.sampleDetails?.customer;

      return (
        <div>
          <div>{typeof customer === 'object' ? customer?.name : customer}</div>
        </div>
      );
    },

    customeraddress: (_props: any) => (
      <div>
        <div>{customerAddress?.line1}</div>
        <div>{customerAddress?.line2}</div>
        <div>
          {customerAddress?.city}, {customerAddress?.state} - {customerAddress?.zip}
        </div>
      </div>
    ),

    collectionby: (_props: any) => {
      const collectionBy = props.sampleDetails?.collectionBy;
      return (
        <div>
          <div>Colleced By : {collectionBy}</div>
        </div>
      );
    },

    hod: (_props: any) => {
      const hod = props.sampleDetails?.hod as ICustomer;
      return (
        <div>
          <div>HOD :{hod?.name}</div>
        </div>
      );
    },

    reviewedby: (_props: any) => {
      const reviewedBy = props.sampleDetails?.reviewedBy as ICustomer;
      return (
        <div style={{ marginTop: 40, fontWeight: 'bold' }}>
          <div>Reviewed By : {reviewedBy?.name}</div>
        </div>
      );
    },

    expdatemmyy: (_props: any) => {
      const expDate = props.sampleDetails?.expDate;
      return (
        <div>
          <div>{expDate ? moment(expDate).format('MM/YYYY') : 'N/A'}</div>
        </div>
      );
    },

    mfgdatemmyy: (_props: any) => {
      const mfgDate = props.sampleDetails?.mfgDate;
      return (
        <div>
          <div>{mfgDate ? moment(mfgDate).format('MM/YYYY') : 'N/A'}</div>
        </div>
      );
    },

    stamp: (_props: any) => {
      return (
        <div>
          <img src={props.laboratory?.details?.stampImage} width={'90mm'} alt="nabl" />
        </div>
      );
    },

    authorizedsignature1: (_props: any) => {
      const authorizedSignature1 = props.sampleDetails?.authorizedSignature1 as ICustomer;
      return (
        <div style={{ marginTop: 40, fontWeight: 'bold' }}>
          <div>Authorized Signature : {authorizedSignature1?.name}</div>
        </div>
      );
    },

    authorizedsignature2: (_props: any) => {
      const authorizedSignature2 = props.sampleDetails?.authorizedSignature2 as ICustomer;
      return (
        <div style={{ marginTop: 40, fontWeight: 'bold' }}>
          <div>Authorized Signature : {authorizedSignature2?.name}</div>
        </div>
      );
    },

    refno: (_props: any) => {
      const refNo = props?.sampleDetails?.sampleCode || 'N/A';

      return (
        <div>
          <div>{refNo}</div>
        </div>
      );
    },

    resultparametertable: (_props: any) => {
      return (
        <div className="print-table-with-border">
          <GeneralTable fields={parameters} />
        </div>
      );
    },

    resultparametertabletwo: (_props: any) => {
      return (
        <div className="print-table-with-border">
          <GeneralTable2 fields={parameters} />
        </div>
      );
    },

    resultparameterpharmatabletwo: (_props: any) => {
      return (
        <div className="print-table-with-border">
          <GeneralPharmaTable2 sample={props.sampleDetails} fields={parameters} />
        </div>
      );
    },
  };

  return (
    <Box className="print">
      <A4
        header={
          <>
            <Box mt={3} display={'flex'} flexDirection={'column'} justifyContent={'center'}>
              {printWithNABL === 'YES' ? (
                <Box>
                  <img src={common.nablLogo} width={'60mm'} alt="nabl" />
                </Box>
              ) : null}
              {printWithNABL === 'YES' ? (
                <Box mb={1} mt={-0.5}>
                  <Typography fontSize={12} fontWeight={'600'}>
                    {props.laboratory?.details?.nabl}
                  </Typography>
                </Box>
              ) : null}
              {}
              {props.laboratory?.details?.labType !== 'PHARMA' ? (
                <Typography fontSize={16} fontWeight={'bold'}>
                  TEST REPORT
                </Typography>
              ) : (
                <>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      paddingBottom: '1px',
                      borderBottom: '1px solid black',
                    }}
                  >
                    <Typography
                      fontSize={16}
                      fontWeight={'bold'}
                      style={{
                        textDecoration: 'underline',
                        marginBottom: '10px',
                        fontFamily: 'Times New Roman',
                      }}
                    >
                      Certificate of Analysis
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      textAlign: 'right',
                      marginTop: '-30px',
                      fontFamily: 'Times New Roman',
                    }}
                  >
                    Analytical Lic No. : GTL/37/40
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      marginTop: '13px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      paddingBottom: '15px',
                      borderBottom: '1px solid black',
                    }}
                  >
                    <Typography
                      fontSize={16}
                      fontWeight={'bold'}
                      style={{
                        textDecoration: 'underline',
                        marginBottom: '10px',
                        fontFamily: 'Times New Roman',
                      }}
                    >
                      Form - 39
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      textAlign: 'center',
                      marginTop: '-20px',
                      fontWeight: 'bold',
                    }}
                  >
                    (150 - E (f) Report of the test or analysis by Approved Institution)
                  </div>
                </>
              )}
            </Box>
            <HTMLRenderer html={headerText} components={components} />
          </>
        }
        body={<HTMLRenderer html={bodyText} components={components} />}
        footer={<HTMLRenderer html={footerText} components={components} />}
        finalFooter={
          <>
            {/* <strong>Note: </strong> This report in full or in part, shall not be published, advertised, used for any
            legal action, unless prior permission has been secured from the Director of the Laboratory. This test report
            IS ONLY FOR THE SAMPLE TESTED.
            <br />
            ............................................................................................End of Report
            ........................................................................................... */}
          </>
        }
      />
    </Box>
  );
}

const mapStateToProps = (state: IReducer) => ({
  laboratory: state.laboratory.current,
  sampleDetails: state.laboratory.sampleDetails,
});

function mapDispatchToProps(dispatch: any) {
  return {
    ...bindActionCreators(
      {
        getSampleById,
        getResultTemplateById,
      },
      dispatch
    ),
  };
}

export const SampleResultPrintV2 = connect(mapStateToProps, mapDispatchToProps)(_SampleResultPrintV2);
