import A4 from 'PdfPages/pages/A4';
import { useEffect, useState } from 'react';
import useQuery from 'utils/getQueryParams';
import { connect } from 'react-redux';
import { IReducer } from 'Reducers/IReducer';
import { bindActionCreators } from 'redux';
import { ILaboratoryDetails } from 'Reducers/LaboratoryReducer/ILaboratoryReducer';
import { searchSample } from 'actions/LaboratoryActions';
import './print.css';
import { ISample, IUser } from 'exactt-types/lib';
import moment from 'moment';
import Papa from 'papaparse';
import { Button } from '@mui/material';

interface SampleAllPrintProps {
  setDisableScroll: (value: boolean) => void;
  searchSample: (
    laboratoryId: string,
    params: {
      page?: number;
      size?: number;
      query?: string;
      startDate?: string;
      endDate?: string;
      testGroup?: string;
      customer?: string;
      status?: string;
      sortByCustomer?: boolean;
    }
  ) => Promise<any>;
  sampleList: ISample[] | [];
  laboratory: ILaboratoryDetails | null;
}

export function _RetainedPrint(props: SampleAllPrintProps) {
  let query = useQuery();

  const [samples, setSamples] = useState<ISample[] | []>([]);

  const onFocus = async () => {
    const filteredSample = await props.searchSample(props.laboratory?.details?._id || '', {
      query: '',
      startDate: query.get('from') as string,
      endDate: query.get('to') as string,
      testGroup: (query.get('testGroup') as string) === 'undefined' ? undefined : (query.get('testGroup') as string),
      customer: (query.get('customer') as string) === 'undefined' ? undefined : (query.get('customer') as string),
      sortByCustomer: true,
    });
    setSamples(filteredSample?.data?.samples);
  };

  const downloadCSV = () => {
    const sampleData = samples.map((sample, index) => ({
      'Sr. No': index + 1,
      'Receipt Date': moment(sample.dateOfDispatch).format('DD/MM/YYYY'),
      'Lab ID': sample.sampleId,
      'Sample Name': sample.name,
      'Retain Date': moment(sample.retainDate).format('DD/MM/YYYY') || 'N/A',
      'Retain by': (sample.retainBy as IUser)?.name || '',
      'Discarded Date': moment(sample.retainDate).add(1, 'month').format('DD/MM/YYYY') || 'N/A',
      'Discarded by': (sample.discordedBy as IUser)?.name || '',
    }));

    try {
      const csv = Papa.unparse(sampleData);
      const blob = new Blob([csv], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `RetainedSamples_${query.get('from')}_${query.get('to')}.csv`;
      link.click();
    } catch (error) {
      console.error('Error generating CSV:', error);
    }
  };

  useEffect(() => {
    props.setDisableScroll(true);
    onFocus();
    // eslint-disable-next-line
  }, []);

  return (
    <A4
      header={
        <>
          <Button onClick={downloadCSV} variant="outlined" style={{ marginTop: 10 }}>
            Download CSV
          </Button>
          {props.laboratory && (
            <>
              <h2 style={{ marginBottom: 2 }}>{props.laboratory.details.name}</h2>
              <div>
                Sample Exit Report for the period from {query.get('from')} to {query.get('to')}
              </div>
              <div>Group Name: {query.get('groupName')}</div>
            </>
          )}
        </>
      }
      landscape={true}
      body={
        <>
          <table style={{ width: '100%', marginTop: 5 }}>
            <thead style={{ border: '1px solid black' }}>
              <tr>
                <th colSpan={8} style={{ borderTop: '1px solid black' }}></th>
              </tr>
              <tr>
                <th
                  style={{
                    textAlign: 'left',
                    width: '3%',
                    border: '1px solid black',
                    padding: '1mm',
                  }}
                >
                  Sr. No
                </th>
                <th
                  style={{
                    textAlign: 'left',
                    width: '3%',
                    border: '1px solid black',
                    padding: '1mm',
                  }}
                >
                  Recipt Date
                </th>
                <th
                  style={{
                    textAlign: 'left',
                    width: '8%',
                    border: '1px solid black',
                    padding: '1mm',
                  }}
                >
                  Lab ID
                </th>

                <th
                  style={{
                    textAlign: 'left',
                    width: '8%',
                    border: '1px solid black',
                    padding: '1mm',
                  }}
                >
                  Sample Name
                </th>

                <th
                  style={{
                    textAlign: 'left',
                    border: '1px solid black',
                    padding: '1mm',
                    width: '5%',
                  }}
                >
                  Retain Date
                </th>
                <th
                  style={{
                    textAlign: 'left',
                    width: '8%',
                    border: '1px solid black',
                    padding: '1mm',
                  }}
                >
                  Retain by
                </th>

                <th
                  style={{
                    textAlign: 'left',
                    width: '5%',
                    border: '1px solid black',
                    padding: '1mm',
                  }}
                >
                  Discarded Date
                </th>
                <th
                  style={{
                    textAlign: 'left',
                    width: '5%',
                    border: '1px solid black',
                    padding: '1mm',
                  }}
                >
                  Discarded by
                </th>
              </tr>
            </thead>
            <tbody>
              {samples?.map((sample, index) => (
                <tr>
                  <td style={{ border: '1px solid black' }}>{index + 1}</td>
                  <td style={{ border: '1px solid black' }}>{moment(sample.dateOfDispatch).format('DD/MM/YYYY')}</td>
                  <td style={{ border: '1px solid black' }}>{sample.sampleId}</td>
                  <td style={{ border: '1px solid black' }}>{sample.name}</td>
                  <td style={{ border: '1px solid black' }}>
                    {moment(sample.retainDate).format('DD/MM/YYYY') || 'N/A'}
                  </td>
                  <td style={{ border: '1px solid black' }}>{(sample.retainBy as IUser)?.name || ''}</td>
                  <td style={{ border: '1px solid black' }}>
                    {moment(sample.retainDate).add(1, 'month').format('DD/MM/YYYY') || 'N/A'}
                  </td>
                  <td style={{ border: '1px solid black' }}>{(sample.discordedBy as IUser)?.name || ''}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      }
      footer={
        <div style={{ borderTop: '1px solid black' }}>
          <div id="page-number"></div>
        </div>
      }
    />
  );
}

const mapStateToProps = (state: IReducer) => ({
  laboratory: state.laboratory.current,
  sampleList: state.laboratory.samples,
});

function mapDispatchToProps(dispatch: any) {
  return {
    ...bindActionCreators({ searchSample }, dispatch),
  };
}

export const RetainedPrint = connect(mapStateToProps, mapDispatchToProps)(_RetainedPrint);
