import { Box, Button, Checkbox, FormControlLabel, Grid, InputBase, Typography } from '@mui/material';
import { IReducer } from 'Reducers/IReducer';
import { IParameters } from 'exactt-types/lib';
import { bindActionCreators } from 'redux';
import AddIcon from '@mui/icons-material/Add';
import { connect } from 'react-redux';
import { ILaboratoryDetails } from 'Reducers/LaboratoryReducer/ILaboratoryReducer';
import { useMemo, useState } from 'react';
import { handleChangeWhiteSpace } from 'utils/removeWhiteSpaces';
import Select from 'react-select';

interface IParameterTableProps {
  value: any[];
  laboratory: null | ILaboratoryDetails;
  setFieldValue: (field: string, value: any) => void;
  options: { label: string; value: string }[];
}

const RenderParameterRowD = ({
  parameter,
  index,
  values,
  setFieldValue,
  deleteRow,
  addRow,
  disabled,
  editMode,
  options,
}: any) => {
  const handleValue = (value: any) => {
    const length = value.length;
    if (value[length - 1] === ' ' && value[length - 2] === ' ') {
      return handleChangeWhiteSpace(value);
    }
    return value;
  };

  return (
    <Box display={'flex'} key={index}>
      <Grid container flex={1} height={45} display={'flex'}>
        {editMode ? (
          <Grid className="table-grid" item xs={1.5}>
            <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <Button
                aria-label={'delete'}
                size={'small'}
                color={'success'}
                onClick={() => {
                  addRow(index);
                }}
              >
                ADD ROW
              </Button>
            </Box>
          </Grid>
        ) : null}

        {editMode ? (
          <Grid className="table-grid" item xs={1.5}>
            <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <Button
                aria-label={'delete'}
                size={'small'}
                color={'error'}
                onClick={() => {
                  deleteRow(index);
                }}
              >
                DELETE
              </Button>
            </Box>
          </Grid>
        ) : null}

        <Grid className="table-grid" item xs={4}>
          <select
            id="demo-simple-select-helper"
            name="department"
            style={{
              width: '100%',
              fontSize: 14,
              border: 'none',
              background: 'none',
              height: '100%',
              textAlign: 'center',
            }}
            disabled={disabled}
            onChange={(e) => {
              const value = e.target.value;
              console.log('value -> ', value);
              const key = `parameters.${index}.name`;
              setFieldValue(key, handleValue(value));
            }}
            value={parameter.name}
          >
            <option value={''} disabled></option>
            {(options || []).map((i: any) => {
              return (
                <option key={i.value} value={i.value}>
                  {i.label}
                </option>
              );
            })}
          </select>
        </Grid>
        <Grid className="table-grid" item xs={1}>
          <InputBase
            style={{ fontSize: 14, paddingLeft: 10, paddingRight: 10 }}
            value={parameter.price}
            disabled={disabled}
            onChange={(e) => {
              const value = e.target.value;
              const key = `parameters.${index}.price`;
              setFieldValue(key, handleValue(value));
            }}
            fullWidth
          />
        </Grid>
      </Grid>
    </Box>
  );
};

function RenderParameterRow(props: any) {
  return useMemo(
    () => <RenderParameterRowD {...props} deleteRow={props.deleteRow} addRow={props.addRow} />,
    // eslint-disable-next-line
    [props.parameter, props.totalValueLength]
  );
}

function PriceListParameterTable(props: IParameterTableProps) {
  const [editTable, setEditTable] = useState(false);

  const deleteRow = (index: number) => {
    const updatedParameters = [...props.value];
    updatedParameters.splice(index, 1);
    props.setFieldValue('parameters', updatedParameters);
  };

  const addRow = (index: number) => {
    const updatedParameters = [...props.value];
    const newParameter: any = {
      name: '',
      price: '',
    };
    updatedParameters.splice(index + 1, 0, newParameter);
    props.setFieldValue('parameters', updatedParameters);
  };

  console.log('props.options -> ', props.options);

  if (props.options.length === 0) {
    return null;
  }

  return (
    <Box>
      <Box display={'flex'} justifyContent={'flex-end'} mt={1} gap={2}>
        <Button
          variant="outlined"
          onClick={() => {
            setEditTable(!editTable);
          }}
        >
          {editTable ? 'Done' : 'Edit Existing Rows'}
        </Button>
        {!editTable ? (
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={() => {
              const updatedParameters = props.value.slice();
              updatedParameters.push({
                name: '',
                price: '',
              });
              props.setFieldValue('parameters', updatedParameters);
            }}
          >
            Add Parameter
          </Button>
        ) : null}
      </Box>
      <Box p={2} mt={2} bgcolor={editTable ? 'rgba(0,100,100,0.1)' : 'rgba(0,0,0,0.02)'} borderRadius={1}>
        <Box>
          <Box display={'flex'}>
            <Grid container flex={1} height={45} display={'flex'}>
              {editTable ? (
                <Grid className="table-grid center-text" item xs={1.5}>
                  <Typography fontWeight={'bold'} fontSize={12}></Typography>
                </Grid>
              ) : null}
              {editTable ? (
                <Grid className="table-grid center-text" item xs={1.5}>
                  <Typography fontWeight={'bold'} fontSize={12}></Typography>
                </Grid>
              ) : null}

              <Grid className="table-grid center-text" item xs={4}>
                <Typography fontWeight={'bold'} fontSize={12}>
                  NAME
                </Typography>
              </Grid>
              <Grid className="table-grid center-text" item xs={1}>
                <Typography fontWeight={'bold'} fontSize={12}>
                  PRICE
                </Typography>
              </Grid>
            </Grid>
          </Box>

          {props.value.map((parameter, index) => {
            if (editTable) {
              return (
                <RenderParameterRowD
                  key={index}
                  parameter={parameter}
                  index={index}
                  totalValueLength={props.value.length}
                  values={props.value}
                  laboratory={props.laboratory}
                  setFieldValue={props.setFieldValue}
                  deleteRow={deleteRow}
                  addRow={addRow}
                  disabled={editTable}
                  editMode={editTable}
                  options={props.options}
                />
              );
            }
            return (
              <RenderParameterRow
                key={index}
                parameter={parameter}
                index={index}
                totalValueLength={props.value.length}
                values={props.value}
                laboratory={props.laboratory}
                setFieldValue={props.setFieldValue}
                deleteRow={deleteRow}
                addRow={addRow}
                options={props.options}
              />
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}

function ParameterTableRenderPrevent(props: IParameterTableProps) {
  return useMemo(
    () => {
      return <PriceListParameterTable {...props} />;
    },
    // eslint-disable-next-line
    [props.value, props.options]
  );
}

const mapStateToProps = (state: IReducer) => ({
  user: state.auth.user,
  laboratory: state.laboratory.current,
});

function mapDispatchToProps(dispatch: any) {
  return {
    ...bindActionCreators({}, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ParameterTableRenderPrevent);
