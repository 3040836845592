import * as Yup from 'yup';

export const CreatePriceListSchema = {
  initialValues: {
    customer: '',
    parameters: [],
  },
  validationSchema: Yup.object().shape({
    customer: Yup.string().required('Customer name is required'),
    parameters: Yup.array()
      .nullable()
      .of(
        Yup.object().shape({
          name: Yup.string().required('Parameter name is required'),
          price: Yup.number().required('Price is required').min(0, 'Price must be greater than or equal to 0'),
        })
      )
      .required('Parameters are required'),
  }),
};
