import { ISample } from 'exactt-types/lib';
import _ from 'lodash';
import { useEffect } from 'react';

export interface IGeneralTableProps {
  sample: ISample | null;
  fields: {
    characteristic: string;
    results: string;
    requirements: string;
    permissibleLimit: string;
    method: string;
    unit: string;
    group: string;
    type: string;
  }[];
  showFooter?: boolean;
  hideResult?: boolean;
}

export default function GeneralPharmaTable2(props: IGeneralTableProps) {
  const fieldsWithGroups = _.groupBy(props.fields, 'group');

  const renderFields: any[] = [];
  const addedGroups: any[] = [];

  const groups = Object.keys(fieldsWithGroups);
  const parameterNames = props.fields.map((field) => field.characteristic);

  const groupHasAssay = groups.map((i) => i.toLowerCase()).filter((i) => i.toLowerCase()?.includes('assay')).length > 0;

  const hasAssay = groupHasAssay;

  props.fields.map((field, index) => {
    if (!field.group) {
      renderFields.push(field);
    }
    if (field.group && !addedGroups.includes(field.group)) {
      const data = fieldsWithGroups[field.group];
      renderFields.push(data);
      addedGroups.push(field.group);
    }
  });

  useEffect(() => {
    setTimeout(() => {
      // get height of `g${index}-title`
      groups.forEach((group, index) => {
        const groupTitle = document.getElementById(`g-${index}-title`);

        if (groupTitle && group) {
          const height = groupTitle.clientHeight;

          const parameters = fieldsWithGroups[group];

          // g-${group}-title-lc
          // g-${group}-title-result
          // g-${group}-title-req

          // check above 3 ids and set height of table accordingly

          const lc = document.getElementById(`g-${index}-title-lc`);
          const result = document.getElementById(`g-${index}-title-result`);
          const req = document.getElementById(`g-${index}-title-req`);

          if (lc) {
            lc.style.height = `${height}px`;
          }

          if (result) {
            result.style.height = `${height}px`;
          }

          if (req) {
            req.style.height = `${height}px`;
          }

          // map of items in group
          parameters.forEach((param, idx) => {
            // get titl, lc, result, req height and set the highest height to all 4
            // and also add check its existance

            const itemTitle = document.getElementById(`g-${index}-item-${idx}`);
            const lc = document.getElementById(`g-${index}-item-${idx}-lc`);
            const result = document.getElementById(`g-${index}-item-${idx}-result`);
            const req = document.getElementById(`g-${index}-item-${idx}-req`);

            const height: number[] = [];
            if (itemTitle) {
              height.push(itemTitle.clientHeight);
            }
            if (lc) {
              height.push(lc.clientHeight);
            }
            if (result) {
              height.push(result.clientHeight);
            }
            if (req) {
              height.push(req.clientHeight);
            }

            const maxHeight = Math.max(...height);

            if (itemTitle) {
              itemTitle.style.height = `${maxHeight}px`;

              if (lc) {
                lc.style.height = `${maxHeight}px`;
              }

              if (result) {
                result.style.height = `${maxHeight}px`;
              }

              if (req) {
                req.style['min-height'] = `${maxHeight}px`;
              }
            }
          });
        }
      });
    }, 1000);
  }, []);

  return (
    <>
      <div className="table-with-border" style={{ width: '100%', marginTop: 20 }}>
        <table style={{ width: '100%' }}>
          <thead>
            <tr>
              <th style={{ width: '10mm' }}>No.</th>
              <th>Test Parameters</th>
              {hasAssay && <th style={{ width: '20mm' }}>Label Claim</th>}
              <th style={{ width: '30%' }}>Results</th>
              <th style={{ width: '30%' }}>Specification {props.sample?.testMethod}</th>
            </tr>
          </thead>

          <tbody>
            {renderFields.map((field, index) => {
              const isGroup = Array.isArray(field);

              const isParameterAssay = String(field.characteristic).toLowerCase()?.includes('assay');
              const isGroupAssay = isGroup ? String(field[0].group).toLowerCase()?.includes('assay') : false;

              const isAssay = isParameterAssay || isGroupAssay;

              let claimUnit = '';

              if (props.sample?.labelClaim) {
                claimUnit = props.sample?.labelClaim.split(' ')[1];
              }

              if (isGroup) {
                const group = groups.indexOf(field[0].group);
                const isAssay = String(field[0].group).toLowerCase()?.includes('assay');
                return (
                  <>
                    <tr key={index} className="print-table-group-title">
                      <td>
                        <div>{index + 1}</div>
                      </td>
                      <td style={{ textAlign: 'left' }}>
                        <div id={`g-${group}-title`}>
                          <div style={{ paddingTop: 2 }}>{field[0].group}</div>
                          {isAssay ? <div>{props.sample?.description}:</div> : null}
                        </div>
                        {field.map((f: any, idx: number) => (
                          <div style={{ paddingTop: 2 }} id={`g-${group}-item-${idx}`}>
                            {f.characteristic}
                          </div>
                        ))}
                      </td>

                      {hasAssay ? (
                        <td>
                          <div id={`g-${group}-title-lc`}></div>
                          {field.map((f: any, idx: number) => {
                            const fieldName = f.characteristic?.replace(/\./g, '');
                            return (
                              <div id={`g-${group}-item-${idx}-lc`} style={{ paddingTop: 2 }}>
                                {props.sample?.additionalFields?.[fieldName]}
                              </div>
                            );
                          })}
                        </td>
                      ) : null}

                      <td>
                        <div id={`g-${group}-title-result`}></div>
                        {field.map((f: any, idx: number) => {
                          const fieldName = f.characteristic?.replace(/\./g, '');
                          const labelClaim = props.sample?.additionalFields?.[fieldName] || '';
                          const claimUnit = labelClaim?.split(' ')[1];
                          if (!props.hideResult) {
                            if (f.type === 'percentageLC' && labelClaim) {
                              return (
                                <div id={`g-${group}-item-${idx}-result`} style={{ paddingTop: 2 }}>
                                  {`${((parseFloat(labelClaim || '') * parseFloat(f.results)) / 100).toFixed(
                                    4
                                  )} ${claimUnit} i.e. ${parseFloat(f.results)} % of Label Amount`}
                                </div>
                              );
                            }
                            return (
                              <div id={`g-${group}-item-${idx}-result`} style={{ paddingTop: 2 }}>
                                {f.results} {f.unit}
                              </div>
                            );
                          }
                        })}
                      </td>
                      <td>
                        <div id={`g-${group}-title-req`}></div>
                        {field.map((f: any, idx: number) => (
                          <div id={`g-${group}-item-${idx}-req`} style={{ paddingTop: 2 }}>
                            {f.characteristic.toLowerCase()?.includes('description')
                              ? props.sample?.sampleDescription
                              : f.requirements}
                          </div>
                        ))}
                      </td>
                    </tr>
                  </>
                );
              }
              return (
                <>
                  <tr key={index} style={{ borderTop: 0 }}>
                    <td>{index + 1}</td>
                    <td>{field.characteristic}</td>
                    {hasAssay ? <td></td> : null}
                    <td>
                      {!props.hideResult ? (
                        <>
                          {field.type === 'percentageLC' && props.sample?.labelClaim
                            ? `$
                          ${((parseFloat(props.sample?.labelClaim || '') * parseFloat(field.results)) / 100).toFixed(
                            4
                          )} ${claimUnit}{' '}
                          i.e. ${parseFloat(field.results)} % of Label Amount`
                            : `${field.results} ${field.unit}`}
                        </>
                      ) : null}
                    </td>
                    <td>
                      {field.characteristic.toLowerCase()?.includes('description')
                        ? props.sample?.sampleDescription
                        : field.requirements}
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
          {props.showFooter && (
            <tfoot>
              <tr>
                <td colSpan={10}>
                  <div
                    style={{
                      width: '100%',
                      height: 10,
                      display: 'flex',
                      justifyContent: 'space-around',
                      fontSize: 12,
                      fontWeight: 'bold',
                      paddingTop: 40,
                      paddingBottom: 10,
                    }}
                  >
                    <div>Analysis By :</div>
                    <div>Checked By :</div>
                    <div>Approved By:</div>
                  </div>
                </td>
              </tr>
            </tfoot>
          )}
        </table>
        <div style={{ marginTop: 10, paddingBottom: 10 }}>
          In the opinion of the undersigned, the sample referred to above is of Standard Quality as defined in{' '}
          {props.sample?.testMethod} the Specification & Act or the rules made there under
        </div>
      </div>
    </>
  );
}
