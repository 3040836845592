import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  shape: {
    borderRadius: 4,
  },
  typography: {
    fontFamily: `Open Sans`,
    fontSize: 14,
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
  },
  palette: {
    primary: {
      main: '#1051c2',
    },
    secondary: {
      main: '#1051c2',
    },
    text: {
      primary: '#000000',
      secondary: '#55637B',
      disabled: '#55637B',
    },
    background: {
      default: '#f5f5f3',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        fontFamily: 'Open Sans',
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        size: 'small',
        margin: 'dense',
      },
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        style: {
          fontFamily: 'Open Sans',
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        size: 'small',
        margin: 'dense',
      },
    },
    MuiButton: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 40,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          paddingTop: 5,
          paddingBottom: 5,
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          fontFamily: 'Open Sans',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'Open Sans',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: 16,
        },
      },
    },
  },
});

export default theme;
