import { useEffect, useState } from 'react';
import { Box, Button, Divider, Grid, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Header from 'components/Header/Header';
import { IHeader } from 'components/Header';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CustomFieldWithLabel } from 'components/CustomFieldWithLabel';
import AsyncSelect from 'react-select/async';
import { IReducer } from 'Reducers/IReducer';
import { ICreatePriceListProps, IFormikCreatePriceList } from './ICreatePriceList';
import { searchCustomer } from 'actions/CustomerActions';
import { createPriceList, getPriceListById } from 'actions/LaboratoryActions';
import { ICustomer, IPriceListParameter } from 'exactt-types/lib';
import { CreatePriceListSchema } from 'FormSchema/CreatePriceListSchema';
import { useFormik } from 'formik';
import useQuery from 'utils/getQueryParams';
import { getParametersList } from 'services/LaboratoryService/LaboratoryService';
import PriceListParameterTable from './PriceListParameterTable';

export interface IPriceListParameterUpdated extends IPriceListParameter {
  group?: string;
}

function _CreatePriceList(props: ICreatePriceListProps) {
  const navigate = useNavigate();
  const laboratoryId = props.laboratory?.details?._id || '';

  // get id from query params
  const query = useQuery();
  const priceListId = query.get('id');

  const [customerSearchQuery, setCustomerSearchQuery] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState<ICustomer | null>(null);
  const [parameters, setParameters] = useState<IPriceListParameterUpdated[]>([]);
  const [options, setOptions] = useState<any[]>([]);

  const breadcrumbsList: IHeader['breadcrumbsList'] = [
    {
      label: 'Dashboard',
      onClick: () => navigate('/'),
    },
    {
      label: 'Price List',
      onClick: () => navigate('/laboratory/price-list'),
    },
  ];

  const formik: IFormikCreatePriceList = useFormik({
    initialValues: CreatePriceListSchema.initialValues,
    validationSchema: CreatePriceListSchema.validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        await props.createPriceList(laboratoryId, {
          laboratory: laboratoryId,
          customer: selectedCustomer?._id || '',
          parameters: values.parameters,
        });
        navigate('/laboratory/price-list');
      } catch (err) {
        console.log(err);
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    props.searchCustomer(laboratoryId, { page: 1, size: 20, query: customerSearchQuery });
    // eslint-disable-next-line
  }, [customerSearchQuery]);

  const fetchParameters = async () => {
    const parameterList = await getParametersList(laboratoryId);

    if (parameterList) {
      const parameters = parameterList.data.parameters
        .sort((a: string, b: string) => a.localeCompare(b))
        .map((i: any) => ({
          label: i,
          value: i,
        }));
      setOptions(parameters);
    }
  };

  useEffect(() => {
    fetchParameters();
  }, []);

  const customersOptions = async (inputValue: string) => {
    const list = await props.searchCustomer(laboratoryId, { page: 1, size: 10, query: inputValue });
    return list.data.customers.map((i: any) => ({
      value: i._id,
      label: i.name,
      option: i,
    }));
  };

  useEffect(() => {
    if (props.priceList) {
      setSelectedCustomer((props.priceList as any).customer);

      formik.setFieldValue('customer', (props.priceList as any).customer?._id || '');
      formik.setFieldValue('parameters', (props.priceList as any).parameters || []);
    }
  }, [props.priceList]);

  useEffect(() => {
    if (priceListId) {
      props.getPriceListById(laboratoryId, priceListId);
    } else {
      setSelectedCustomer(null);
      setParameters([]);
    }
  }, []);

  console.log('formik.values -> ', formik.values);
  return (
    <Box>
      <Header breadcrumbsList={breadcrumbsList} title="Price List" />
      <Box
        pt={12}
        ml={3}
        display={'flex'}
        justifyContent={'center'}
        alignContent={'center'}
        flexDirection={'column'}
        width={'95%'}
      >
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <CustomFieldWithLabel width={120} label="Customer">
              <AsyncSelect
                loadOptions={customersOptions}
                value={{ value: selectedCustomer?._id, label: selectedCustomer?.name }}
                isDisabled={!!priceListId}
                defaultOptions={(props.customerList || []).map((i) => ({
                  label: i.name,
                  value: i._id,
                }))}
                onChange={(newValue: any) => {
                  const findCustomer = props.customerList.find((i) => i._id === newValue?.value);
                  if (findCustomer) setSelectedCustomer(findCustomer);
                  formik.setFieldValue('customer', newValue?.value || '');
                }}
              />
            </CustomFieldWithLabel>

            {formik.touched.customer && formik.errors.customer}
          </Grid>
          <Grid item xs={4}>
            <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
              <Box>
                <Button variant="outlined" onClick={() => navigate('/')}>
                  Cancel
                </Button>
              </Box>
              <Box ml={1}>
                <Button
                  disabled={!selectedCustomer}
                  variant="contained"
                  onClick={() => {
                    formik.handleSubmit();
                  }}
                >
                  {priceListId ? 'Update' : 'Save'}
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
        {selectedCustomer ? (
          <>
            <Box display={'flex'} flexDirection={'column'} gap={1}>
              <Typography variant="h6" color={'#000'} fontSize={14}>
                Parameters
              </Typography>
              <Divider />
              <Box
                style={{
                  height: '70vh',
                  overflowX: 'hidden',
                  overflowY: 'scroll',
                  padding: '10px',
                  backgroundColor: '#f5f5f5',
                  borderRadius: '5px',
                }}
              >
                <PriceListParameterTable
                  value={formik.values.parameters}
                  setFieldValue={formik.setFieldValue}
                  options={options}
                />
              </Box>
            </Box>
          </>
        ) : (
          <Grid item xs={12} mt={2}>
            <Typography style={{ textTransform: 'uppercase' }} fontWeight={'500'} fontSize={14}>
              Please Select Customer
            </Typography>
            <Divider />
          </Grid>
        )}
      </Box>
    </Box>
  );
}

const mapStateToProps = (state: IReducer) => {
  return {
    laboratory: state.laboratory.current,
    customerList: state.laboratory.searchCustomer,
    priceList: state.laboratory.priceListDetails,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    ...bindActionCreators(
      {
        searchCustomer,
        getParametersList,
        createPriceList,
        getPriceListById,
      },
      dispatch
    ),
  };
}

export const CreatePriceList = connect(mapStateToProps, mapDispatchToProps)(_CreatePriceList);
