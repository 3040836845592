import actionTypes from 'constants/ActionTypes';
import { IActionInterface } from 'Reducers/IActionDispatch';
import { ILaboratoryReducer } from 'Reducers/LaboratoryReducer';

const initialState: ILaboratoryReducer = {
  list: [],
  current: null,
  tasks: [],
  users: [],
  customers: [],
  searchCustomer: [],
  customer: [],
  masters: [],
  testGroups: [],
  samples: [],
  sampleDetails: null,
  reference: [],
  department: [],
  audit: [],
  resultTemplate: [],
  testReault: [],
  sampleResult: [],
  getParametersList: [],
  priceList: [],
  priceListDetails: null,
  count: {},
};

export const LaboratoryReducer = (
  state: ILaboratoryReducer = initialState,
  action: IActionInterface
): ILaboratoryReducer => {
  switch (action.type) {
    case actionTypes.laboratory.GET_LABORATORYS:
      return {
        ...state,
        list: action.payload.list,
      };

    case actionTypes.priceList.GET_PRICE_LIST:
      return {
        ...state,
        priceList: action.payload.list,
      };

    case actionTypes.priceList.GET_PRICE_LIST_BY_ID:
      return {
        ...state,
        priceListDetails: action.payload.data,
      };

    case actionTypes.laboratory.GET_LABORATORY_BY_ID:
      return {
        ...state,
        current: {
          details: action.payload.laboratory,
          access: action.payload.access,
          departments: action.payload.departments,
        },
      };

    case actionTypes.sample.GET_MY_TASKS:
      return {
        ...state,
        tasks: action.payload.list,
      };

    case actionTypes.department.GET_DEPARTMENT:
      return {
        ...state,
        //@ts-ignore
        current: {
          ...state.current,
          departments: action.payload.list,
        },

        count: { department: action.payload && action.payload.count },
      };

    case actionTypes.department.SEARCH_DEPARTMENT:
      return {
        ...state,
        //@ts-ignore
        current: {
          ...state.current,
          departments: action.payload.list,
        },

        count: { department: action.payload && action.payload.count },
      };

    case actionTypes.users.GET_LABORATORY_USERS_LIST:
      return {
        ...state,
        users: action.payload.list,
      };

    case actionTypes.customer.GET_CUSTOMER_LIST:
      return {
        ...state,
        customers: action.payload.list,
      };

    case actionTypes.customer.GET_CUSTOMER:
      return {
        ...state,
        customer: action.payload.data,
      };

    case actionTypes.customer.SEARCH_CUSTOMER:
      return {
        ...state,
        customer: action.payload.list,
        searchCustomer: action.payload.list,
        count: { customer: action.payload && action.payload.count },
      };

    case actionTypes.master.GET_MASTER_LIST:
      return {
        ...state,
        masters: action.payload.list,
      };
    case actionTypes.master.SEARCH_MASTER:
      return {
        ...state,
        masters: action.payload.list,
        count: { master: action.payload && action.payload.count },
      };

    case actionTypes.testgroup.GET_TEST_GROUP:
      return {
        ...state,
        testGroups: action.payload.list,
      };

    case actionTypes.testgroup.SEARCH_TESTGROUP:
      return {
        ...state,
        testGroups: action.payload.list,
        count: { testGroup: action.payload && action.payload.count },
      };

    case actionTypes.resultTemplate.GET_RESULT_TEMPLATE:
      return {
        ...state,
        resultTemplate: action.payload.list,
      };

    case actionTypes.sample.GET_SAMPLE:
      return {
        ...state,
        samples: action.payload.list,
      };

    case actionTypes.sample.SEARCH_SAMPLE:
      return {
        ...state,
        samples: action.payload.reset ? action.payload.list : [...(state.samples || []), ...action.payload.list],
        count: { sample: action.payload && action.payload.count },
      };

    case actionTypes.sample.GET_SAMPLE_BY_ID:
      return {
        ...state,
        sampleDetails: action.payload.data,
      };

    case actionTypes.laboratory.RESET_CURRENT_LABORATORY:
      return {
        ...state,
        current: null,
      };

    case actionTypes.reference.GET_REFERENCE:
      return {
        ...state,
        reference: action.payload.list,
        count: { reference: action.payload && action.payload.count },
      };

    case actionTypes.reference.SEARCH_REFERENCE:
      return {
        ...state,
        reference: action.payload.list,
        count: { reference: action.payload && action.payload.count },
      };

    case actionTypes.audit.GET_AUDIT:
      return {
        ...state,
        reference: action.payload.list,
      };

    case actionTypes.resultTemplate.SEARCH_RESULT_TEMPLATE:
      return {
        ...state,
        resultTemplate: action.payload.list,
        count: { resultTemplate: action.payload && action.payload.count },
      };

    case actionTypes.auth.LOGOUT:
      return initialState;

    default:
      return state;
  }
};
